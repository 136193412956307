import { useTheme } from "styled-components";
import { HBox, VBox } from "../../styles/Flexbox.styled";
import { Button } from "@ui5/webcomponents-react";
import Markdown from "marked-react";
import { AdType } from "../../models/enums/AdType";
import useAd from "../../hooks/useAd";

const SideAd = () => {
  const theme = useTheme();
  const { ads, close, ref } = useAd(AdType.Side);

  return (
    <VBox
      overflowY="hidden"
      overflowX="hidden"
      position="absolute"
      width="100%"
      height="100%"
      className="PigeonMap__ads"
      style={{ pointerEvents: "none" }}
    >
      <VBox
        ref={ref}
        Padding="5px"
        width="200px"
        height="100%"
        position="absolute"
        Top="0"
        Right={ads ? "0" : "-300px"}
        style={{ transition: "0.5s" }}
        backgroundColor={theme.palette.White}
        shadow="small"
      >
        <HBox Padding="0 5px 0 0" justifyContent="flex-end">
          <Button onClick={close} icon="decline" />
        </HBox>
        <Markdown>{ads?.mdText}</Markdown>
      </VBox>
    </VBox>
  );
};

export default SideAd;
