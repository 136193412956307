import { ToolbarButton } from "@ui5/webcomponents-react";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";

interface BackToolbarButtonProps {
  onClick?: () => void;
}

const BackToolbarButton = ({ onClick }: BackToolbarButtonProps) => {
  const navigate = useNavigate();
  return (
    <ToolbarButton
      design="Transparent"
      text={breakpoints.isPhone() ? "" : getText("General.Back")}
      icon="slim-arrow-left"
      onClick={() => {
        onClick && onClick();
        navigate(-1);
      }}
    />
  );
};

export default BackToolbarButton;
