import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "./styles/global.styled";
import ReactDOM from "react-dom/client";
import RegionDetector from "./locales/RegionDetector";
import { ThemeProvider } from "@ui5/webcomponents-react";
import { initi18n } from "./locales/initI18n";

initi18n();

document.documentElement.lang = RegionDetector.GetUserCountry().code;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <GlobalStyle />
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
