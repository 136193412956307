import { useContext } from "react";
import WithConfirmDialogProps from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { MessageContext } from "../hoc/withConfirmDialog/withConfirmDialog";

const useMessage = (): WithConfirmDialogProps => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return { openMessage: context.openMessage };
};

export default useMessage;
