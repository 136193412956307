import { Base } from "./Base";
import { AdType } from "./enums/AdType";

export default class Ad extends Base {
  "md": string;
  "mdText": string;
  "priority" = 1;
  "type": AdType[] = [];
  "expired": Date | null = null;

  fillData(data: object): this {
    super.fillData(data);

    if (this.expired) {
      this.expired = new Date(this.expired);
    }

    return this;
  }

  isExpired(): boolean {
    if (!this.expired) return false;
    return this.expired.getTime() < Date.now();
  }

  hasType(type?: AdType): boolean {
    if (!type) return true;
    return this.type.includes(type);
  }
}
