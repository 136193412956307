import { ComponentType, memo } from "react";
import { addPair, removePair } from "../../services/PigeonPairService";

import { MessageBoxAction } from "@ui5/webcomponents-react";
import Pigeon from "../../models/Pigeon";
import PigeonPair from "../../models/PigeonPair";
import { WithPigeonPairHandlerProps } from "./WithPigeonPairHandlerProps";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import usePigeonPairs from "../../hooks/usePigeonPairs";
import useMessage from "../../hooks/useMessage";

function withPigeonPairHandler<P extends WithPigeonPairHandlerProps>(
  Component: ComponentType<P>
) {
  const WithComponent = (props: Omit<P, keyof WithPigeonPairHandlerProps>) => {
    const dispatch = useAppDispatch();
    const { openMessage } = useMessage();
    const { invalidate } = usePigeonPairs();
    const onAddPigeonPair = async (
      male: Pigeon,
      female: Pigeon,
      year: number
    ) => {
      const pair = PigeonPair.create({
        male,
        female,
      });
      pair.season.year = year;
      try {
        const addedPair = await addPair(pair);
        invalidate();
        return addedPair;
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
    };
    const onRemovePigeonPair = async (pair: PigeonPair) => {
      const confirm = await openMessage(
        getText("withPigeonPairHandler.ConfirmRemovePair", {
          maleRingNumber: pair.maleRingNumber,
          femaleRingNumber: pair.femaleRingNumber,
        })
      );
      if (confirm !== MessageBoxAction.OK) return;

      try {
        await removePair(pair.id);
        invalidate();
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
      return;
    };
    return (
      <Component
        {...(props as unknown as P)}
        onAddPigeonPair={onAddPigeonPair}
        onRemovePigeonPair={onRemovePigeonPair}
      />
    );
  };

  return memo(WithComponent);
}

export default withPigeonPairHandler;
