import { ComponentType, createContext, useState } from "react";

import { Toast } from "@ui5/webcomponents-react";
import ToastPlacement from "@ui5/webcomponents/dist/types/ToastPlacement";
import WithToastProps from "./WithToastProps";
import { breakpoints } from "../../styles/Devices";

const ToastContext = createContext<WithToastProps | undefined>(undefined);

function withToast<P extends WithToastProps>(Component: ComponentType<P>) {
  return (props: Omit<P, keyof WithToastProps>) => {
    const [isVisible, setIsVisible] = useState(false);
    const [Message, SetMessage] = useState<string>("");
    const [onConfirm, setOnConfirm] = useState<() => Promise<void>>(
      async () => {
        setIsVisible(false);
      }
    );

    const showToast = async (message: string) => {
      setIsVisible(true);
      SetMessage(message);
      return new Promise((resolve) => {
        setOnConfirm(() => async () => {
          setIsVisible(false);
          resolve(undefined);
        });
      });
    };

    return (
      <ToastContext.Provider value={{ showToast }}>
        <Toast
          style={{ zIndex: 9999 }}
          placement={
            breakpoints.isPhone()
              ? ToastPlacement.MiddleCenter
              : ToastPlacement.BottomCenter
          }
          open={isVisible}
          onClose={onConfirm}
        >
          {Message}
        </Toast>
        <Component {...(props as P)} showToast={showToast} />
      </ToastContext.Provider>
    );
  };
}

export { ToastContext };

export default withToast;
