import { ComponentType, memo } from "react";
import {
  assignToSeason,
  updateAssignment,
} from "../../services/PigeonSeasonAssignmentService";

import ConfirmMessageHelper from "../../utils/ConfirmMessageHelper";
import { MessageBoxAction } from "@ui5/webcomponents-react";
import Pigeon from "../../models/Pigeon";
import PigeonSeasonAssignment from "../../models/PigeonSeasonAssignment";
import { SeasonAssignmentGroup } from "../../models/enums/SeasonAssignmentGroup";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import usePigeonAssignments from "../../hooks/usePigeonAssignments";
import { withPigeonSeasonAssignmentHandlerProps } from "./withPigeonSeasonAssignmentHandlerProps";
import useMessage from "../../hooks/useMessage";

function withPigeonSeasonAssignmentHandler<
  P extends withPigeonSeasonAssignmentHandlerProps
>(Component: ComponentType<P>) {
  const WithComponent = (
    props: Omit<P, keyof withPigeonSeasonAssignmentHandlerProps>
  ) => {
    const dispatch = useAppDispatch();
    const { openMessage } = useMessage();
    const { invalidate } = usePigeonAssignments();
    const onChangeGroup = async (assignment: PigeonSeasonAssignment) => {
      const textOld = getText("Pigeon.group.Old");
      const textYoung = getText("Pigeon.group.Young");
      const textBreeding = getText("Pigeon.group.Breeding");
      const actions = [
        textOld,
        textYoung,
        textBreeding,
        MessageBoxAction.Ignore,
      ];
      const assignMessage = await openMessage(
        getText("withPigeonSeasonAssignmentHandler.ChangeGroupPrompt", {
          currentGroup: assignment.ReadableGroup,
        }),
        {
          actions,
        }
      );
      if (assignMessage === textOld) {
        await updateAssignment(assignment.id, SeasonAssignmentGroup.Old);
      } else if (assignMessage === textYoung) {
        await updateAssignment(assignment.id, SeasonAssignmentGroup.Young);
      } else if (assignMessage === textBreeding) {
        await updateAssignment(assignment.id, SeasonAssignmentGroup.Breeding);
      }
      invalidate();
    };
    const setAssignment = async (
      pigeon: Pigeon,
      year: number,
      predefinedGroup?: SeasonAssignmentGroup
    ) => {
      const group =
        predefinedGroup ||
        (await ConfirmMessageHelper.getSeasonAssignment(openMessage));
      if (!group) return;
      try {
        await assignToSeason(pigeon, year, group);
        invalidate();
      } catch (e) {
        dispatch(setError(e));
      }
    };
    return (
      <Component
        {...(props as unknown as P)}
        onChangeGroup={onChangeGroup}
        setAssignment={setAssignment}
      />
    );
  };

  return memo(WithComponent);
}

export default withPigeonSeasonAssignmentHandler;
