import { AdType } from "../models/enums/AdType";
import { incrementAdClick } from "../services/AdsService";
import CookieHelper from "./CookieHelper";

export default class AdHelper {
  static linkClickHandler() {
    return (event: MouseEvent) => {
      let target = event.target as HTMLElement;

      const maxSearchDepth = 3;
      let searchDepth = 0;
      while (target && target.tagName !== "A" && searchDepth < maxSearchDepth) {
        searchDepth++;
        target = target.parentElement as HTMLElement;
      }

      if (target && target.tagName === "A") {
        const anchor = target as HTMLAnchorElement;
        incrementAdClick(anchor.href);
      }
    };
  }

  static getCookie(type: AdType, name = "") {
    switch (type) {
      case AdType.Side:
        return CookieHelper.sideAdShown;
      case AdType.Horizontal:
        return CookieHelper.getHorizontalAdShown(name);
      case AdType.Vertical:
        return CookieHelper.getVerticalAdShown(name);
      default:
        return null;
    }
  }

  static isAddShownToday(type: AdType, name = "") {
    const cookie = AdHelper.getCookie(type, name);
    if (!cookie) return false;
    const adDate = cookie.get();

    if (!adDate) return false;

    const today = new Date();
    const adShownDate = new Date(adDate);
    return (
      today.getDate() === adShownDate.getDate() &&
      today.getMonth() === adShownDate.getMonth()
    );
  }
}
