export interface IErrorMessage {
  title: string;
  message: string;
  height?: number;
}

export interface IErrorMessages {
  [key: string]: IErrorMessage;
}

const ErrorMessages: IErrorMessages = {
  WRONG_PASSWORD_OR_USERNAME: {
    title: "WrongData",
    message: "UsernameOrPasswordWrong",
    height: 2.5,
  },
  USERNAME_REQUIRED: {
    title: "UsernameRequired",
    message: "UsernameRequiredMessage",
    height: 2.5,
  },
  PASSWORD_REQUIRED: {
    title: "PasswordRequired",
    message: "PasswordRequiredMessage",
    height: 2.5,
  },
  USERNAME_ALREADY_EXISTS: {
    title: "UsernameAlreadyExists",
    message: "UsernameAlreadyExistsMessage",
    height: 2.5,
  },
  EMAIL_REQUIRED: {
    title: "EmailRequired",
    message: "EmailRequiredMessage",
    height: 2.5,
  },
  EMAIL_INVALID: {
    title: "EmailInvalid",
    message: "EmailInvalidMessage",
    height: 2.5,
  },
  EMAIL_ALREADY_EXISTS: {
    title: "EmailAlreadyExists",
    message: "EmailAlreadyExistsMessage",
    height: 2.5,
  },
  PHONE_NUMBER_INVALID: {
    title: "PhoneNumberInvalid",
    message: "PhoneNumberInvalidMessage",
    height: 2.5,
  },
  PHONE_NUMBER_ALREADY_EXISTS: {
    title: "PhoneNumberAlreadyExists",
    message: "PhoneNumberAlreadyExistsMessage",
    height: 2.5,
  },
  USERNAME_TOO_SHORT: {
    title: "UsernameTooShort",
    message: "UsernameTooShortMessage",
    height: 2.5,
  },
  PASSWORD_TOO_SHORT: {
    title: "PasswordTooShort",
    message: "PasswordTooShortMessage",
    height: 2.5,
  },
  DIFFERENT_PASSWORDS: {
    title: "PasswordsNotEqual",
    message: "PasswordsNotEqualMessage",
    height: 3.0,
  },
  EMAIL_NOT_VERIFIED: {
    title: "EmailNotVerified",
    message: "EmailNotVerifiedMessage",
  },
  PRIVATE_TAG_ERROR: {
    title: "Error_PrivateTag",
    message: "Error_PrivateTagMessage",
  },
  WRONG_TAG_TYPE: {
    title: "Error_WrongTagType",
    message: "Error_WrongTagTypeMessage",
  },
  TAG_NOT_FOUND: {
    title: "Error_TagNotFound",
    message: "Error_TagNotFoundMessage",
  },
  PREDEFINED_TAG_DELETE_ERROR: {
    title: "Error_PredefinedTagDelete",
    message: "Error_PredefinedTagDeleteMessage",
  },
  PREDEFINED_TAG_UPDATE_ERROR: {
    title: "Error_PredefinedTagUpdate",
    message: "Error_PredefinedTagUpdateMessage",
  },
  WRONG_TYPE_OF_OVERLAY_URL_TYPE: {
    title: "Error_WrongTypeOfOverlayUrlType",
    message: "Error_WrongTypeOfOverlayUrlTypeMessage",
  },
  END_TAG_REQUIRED: {
    title: "Error_EndTagRequired",
    message: "Error_EndTagRequiredMessage",
  },
  OPEN_FLIGHT_REFER: {
    title: "Error_OpenFlightRefer",
    message: "Error_OpenFlightReferMessage",
  },
  OPEN_FLIGHT_REFER_TYPE: {
    title: "Error_OpenFlightReferType",
    message: "Error_OpenFlightReferTypeMessage",
  },
  END_TAG_NOT_POSSIBLE: {
    title: "Error_EndTagNotPossible",
    message: "Error_EndTagNotPossibleMessage",
  },
  OPEN_FLIGHT_CREATE_ONLY_PLANNER: {
    title: "Error_OpenFlightCreatePlanner",
    message: "Error_OpenFlightCreatePlannerMessage",
  },
  DEFAULT_TAG_ERROR_IS_NOT_COTE: {
    title: "Error_DefaultTagErrorIsNotCote",
    message: "Error_DefaultTagErrorIsNotCoteMessage",
  },
  USER_NOT_FOUND: {
    title: "Error_UserNotFound",
    message: "Error_UserNotFoundMessage",
  },
  USER_IS_NOT_REGULAR: {
    title: "Error_UserIsNotRegular",
    message: "Error_UserIsNotRegularMessage",
  },
  REQUEST_IS_PROCESSED: {
    title: "Error_RequestIsProcessed",
    message: "Error_RequestIsProcessedMessage",
  },
  OPEN_FLIGHT_REFER_OWNER: {
    title: "Error_OpenFlightReferOwner",
    message: "Error_OpenFlightReferOwnerMessage",
  },
  OPEN_FLIGHT_UPDATE_NOT_ALLOWED: {
    title: "Error_OpenFlightUpdateNotAllowed",
    message: "Error_OpenFlightUpdateNotAllowedMessage",
  },
  DISTRICT_REQUIRED: {
    title: "Error_DistrictRequired",
    message: "Error_DistrictRequiredMessage",
  },
  BRANCH_REQUIRED: {
    title: "Error_BranchRequired",
    message: "Error_BranchRequiredMessage",
  },
  SECTION_REQUIRED: {
    title: "Error_SectionRequired",
    message: "Error_SectionRequiredMessage",
  },
  DISTRICT_BRANCH_SECTION_NOT_MATCH: {
    title: "Error_DistrictBranchSectionNotMatch",
    message: "Error_DistrictBranchSectionNotMatchMessage",
  },
  DISTRICT_ALREADY_FILLED: {
    title: "Error_DistrictAlreadyFilled",
    message: "Error_DistrictAlreadyFilledMessage",
  },
  PIGEONMAP_TYPE_USER: {
    title: "Error.PigeonMapTypeUser",
    message: "Error.PigeonMapTypeUserMessage",
  },
  OUTSIDE_TYPE_USER: {
    title: "Error.OutsideTypeUser",
    message: "Error.OutsideTypeUserMessage",
  },
  CANNOT_UPDATE_FLIGHT: {
    title: "Error.CannotUpdateFlight",
    message: "Error.CannotUpdateFlightMessage",
  },
  FINISH_FLIGHT_UPDATE_NOT_ALLOWED: {
    title: "Error.FinishFlightUpdateNotAllowed",
    message: "Error.FinishFlightUpdateNotAllowedMessage",
  },
  FLIGHT_NOT_VALID: {
    title: "Error.CannotUpdateFlight",
    message: "Error.CannotUpdateFlightMessage",
  },
  FLIGHT_UPDATE_NOT_ALLOWED: {
    title: "Error.FlightUpdateNotAllowed",
    message: "Error.FlightUpdateNotAllowedMessage",
  },
  ALREADY_JOINED_FLIGHT: {
    title: "Error.AlreadyJoinedFlight",
    message: "Error.AlreadyJoinedFlightMessage",
  },
  FLIGHT_DELETE_NOT_ALLOWED: {
    title: "Error.FlightDeleteNotAllowed",
    message: "Error.FlightDeleteNotAllowedMessage",
  },
  JOIN__NOT_ALLOWED_ANOTHER_DISTRICT: {
    title: "Error.JoinNotAllowed",
    message: "Error.JoinNotAllowedMessage",
  },
  NOTFOUND: {
    title: "Error.NotFound",
    message: "Error.NotFoundMessage",
  },
  FORBIDDEN: {
    title: "Error.Forbidden",
    message: "Error.ForbiddenMessage",
  },
  ALREADY_EXISTS: {
    title: "Error.AlreadyExists",
    message: "Error.AlreadyExistsMessage",
  },
  BUYER_NAME_IS_REQUIRED: {
    title: "Error.BuyerNameIsRequired",
    message: "Error.BuyerNameIsRequiredMessage",
  },
  SEASON_IS_REQUIRED: {
    title: "Error.SeasonIsRequired",
    message: "Error.SeasonIsRequiredMessage",
  },
  BROOD_IS_INVALID: {
    title: "Error.BroodIsInvalid",
    message: "Error.BroodIsInvalidMessage",
  },
  DEFINED_SEX_IS_CHANGED: {
    title: "Error.DefinedSexIsChanged",
    message: "Error.DefinedSexIsChangedMessage",
  },
  NOT_FERTILE_EGG: {
    title: "Error.NotFertileEgg",
    message: "Error.NotFertileEggMessage",
  },
  PIGEON_NOT_ACTIVE: {
    title: "Error.PigeonNotActive",
    message: "Error.PigeonNotActiveMessage",
  },
  INVALID_MOVE: {
    title: "Error.InvalidMove",
    message: "Error.InvalidMoveMessage",
  },
  EGG_IS_BORN: {
    title: "Error.EggIsBorn",
    message: "Error.EggIsBornMessage",
  },
  DATE_OF_LAYING_EMPTY: {
    title: "Error.DateOfLayingEmpty",
    message: "Error.DateOfLayingEmptyMessage",
  },
  MAP_FLIGHT_ERROR: {
    title: "Error.MapFlightError",
    message: "Error.MapFlightErrorMessage",
  },
};

export default ErrorMessages;
