import {
  Button,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import { EggState } from "../models/enums/EggStateEnum";
import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import WithEggHandlerProps from "../hoc/withEggHandler/withEggHandlerProps";
import useEggs from "../hooks/useEggs";
import { useNavigate } from "react-router-dom";
import withEggHandler from "../hoc/withEggHandler/withEggHandler";
import BaseDialogProps from "../models/BaseDialogProps";
import DialogResult from "../models/DialogResult";
import EggInformation, { EggInformationProps } from "./modals/EggInformation";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

interface PigeonEggTableProps {
  pigeonId: number | string;
  year?: string | number;
}
const PigeonEggTable = ({
  pigeonId,
  year,
  onRemoveEgg,
  onHatchEgg,
  openDialog,
}: PigeonEggTableProps & WithEggHandlerProps & WithDialogsProps) => {
  const navigate = useNavigate();
  const { eggs, lastMeta, fetchNextPage } = useEggs(
    new PageOptions({
      filters: !year
        ? `pigeonPair.maleId==${pigeonId}||pigeonPair.femaleId==${pigeonId}`
        : `(pigeonPair.maleId==${pigeonId}&&pigeonPair.season.year==${year})||(pigeonPair.femaleId==${pigeonId}&&pigeonPair.season.year==${year})`,
    })
  );

  return (
    <Table
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell minWidth="200px">Numer obrączki</TableHeaderCell>
          <TableHeaderCell minWidth="200px">
            Data złożenia jajka
          </TableHeaderCell>
          <TableHeaderCell width="100px">Status</TableHeaderCell>
          <TableHeaderCell width="100px">Lęg</TableHeaderCell>
          <TableHeaderCell width="100px"></TableHeaderCell>
          <TableHeaderCell width="60px"></TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        if (!key) return;
        openDialog("EggInformation", { eggId: key });
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text="More"
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {eggs?.map((egg) => (
        <TableRow
          interactive
          rowKey={egg.id?.toString()}
          id={egg.id.toString()}
          key={`pigeon-pair-egg-${egg.id}`}
        >
          <TableCell>{egg.pigeon?.RingNumber || "-"}</TableCell>
          <TableCell>{Formatter.DateTime(egg.dateOfLaying)}</TableCell>
          <TableCell>{egg.ReadableStatus}</TableCell>
          <TableCell>{egg.brood}</TableCell>
          <TableCell>
            {egg.status === EggState.Fertile && (
              <Button onClick={() => onHatchEgg(egg)}>Wykluj</Button>
            )}
          </TableCell>
          <TableCell>
            {egg.status === EggState.Fertile && (
              <Button
                design="Negative"
                icon="delete"
                onClick={() => onRemoveEgg(egg)}
              ></Button>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "EggInformation": {
      return (
        <EggInformation
          {...(props as EggInformationProps)}
          onClose={() => onClose(DialogResult.Close())}
        />
      );
    }
  }
};

export default withEggHandler(withDialogs(PigeonEggTable, renderDialogs));
