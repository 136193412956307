import BaseFilter from "./BaseFilter";
import { ChildFilterType } from "./ChildFilterType";
import { ChildFilterValue } from "./ChildFilterValue";
import { FilterRule } from "../enums/FilterRule";
import { IBaseFilter } from "./IBaseFilter";
import IChildFilter from "./IChildFilter";
import ParentFilter from "./ParentFilter";
import DefinedFilters from "./DefinedFilters";
import { FilterName } from "../enums/FilterName";

export default class ChildFilter extends BaseFilter implements IChildFilter {
  protected static _definedFilters: DefinedFilters;
  private type!: ChildFilterType;
  private name!: string;
  private queryName!: string;
  private displayName!: string;
  private rule!: FilterRule;
  private placeholder!: string;
  private value!: ChildFilterValue;
  private transformCallback!: (value: ChildFilterValue) => ChildFilterValue;

  constructor(
    name: FilterName,
    initValue?: ChildFilterValue,
    activeFilters?: IBaseFilter[]
  );
  constructor(queryName: string, rule: FilterRule, value: ChildFilterValue);
  constructor(
    name: FilterName | string,
    initValue?: ChildFilterValue | FilterRule,
    activeFilters?: IBaseFilter[] | ChildFilterValue
  ) {
    super();
    if (!!activeFilters && !Array.isArray(activeFilters)) {
      this._useSimpleConstructor(
        name as string,
        initValue as FilterRule,
        activeFilters as ChildFilterValue
      );
    } else {
      this._useDefinedConstructor(
        name as FilterName,
        initValue as ChildFilterValue,
        activeFilters as IBaseFilter[]
      );
    }
  }

  private _useSimpleConstructor(
    queryName: string,
    rule: FilterRule,
    value: ChildFilterValue
  ) {
    this.name = queryName;
    this.queryName = queryName;
    this.rule = rule;
    this.value = value;
    this.transformCallback = (value: ChildFilterValue) => value;
  }

  private _useDefinedConstructor(
    name: FilterName,
    initValue?: ChildFilterValue,
    activeFilters?: IBaseFilter[]
  ) {
    const filterObject = this._getDefinedFilter(name);
    this.name = name;
    this.queryName = filterObject.queryName;
    this.displayName = filterObject.displayName;
    this.placeholder = filterObject.placeholder;
    this.rule = filterObject.rule;
    this.type = filterObject.type;
    this.transformCallback = filterObject.transform.bind(filterObject);
    this.value = this._getValue(name, activeFilters) || initValue;
  }

  //#region Getters
  public get Type(): ChildFilterType {
    return this.type;
  }

  public get Name(): string {
    return this.name;
  }

  public get DisplayName(): string {
    return this.displayName || this.name;
  }

  public get Rule(): FilterRule {
    return this.rule;
  }

  public get Placeholder(): string {
    return this.placeholder;
  }

  public get Value(): ChildFilterValue {
    return this.value;
  }
  //#endregion

  //#region Setters
  public set Value(value: ChildFilterValue) {
    this.value = value;
  }
  //#endregion

  //#region Public methods
  transform() {
    if (this.getTransformedValue() === undefined && !this._isOneArgumentRule())
      return "";
    return this._getRaw();
  }
  isText() {
    return this.type === ChildFilterType.Text;
  }
  isNumber() {
    return this.type === ChildFilterType.Number;
  }
  isBoolean() {
    return this.type === ChildFilterType.Boolean;
  }
  //#endregion

  //#region Private methods
  private _getDefinedFilter(name: FilterName) {
    if (!ChildFilter._definedFilters) {
      ChildFilter._definedFilters = new DefinedFilters();
    }
    return ChildFilter._definedFilters.getFilter(name);
  }
  private _getValue(name: string, filters?: IBaseFilter[]) {
    if (!filters) return null;
    let filter: IChildFilter | undefined;
    filters.forEach((f) => {
      if (ParentFilter.is(f)) {
        filter = f.getChilds().find((child) => child.Name === name);
      } else if (ChildFilter.is(f)) {
        if (f.Name === name) filter = f;
      }
    });
    if (!filter) return null;
    return filter.Value;
  }

  private getTransformedValue() {
    return this.transformCallback(this.value);
  }

  private _isOneArgumentRule() {
    return (
      this.rule === FilterRule.IS_NULL || this.rule === FilterRule.IS_NOT_NULL
    );
  }

  private _getRaw() {
    switch (this.rule) {
      case FilterRule.EQUALS:
      case FilterRule.NOT_EQUALS:
      case FilterRule.GREATER_THAN:
      case FilterRule.GREATER_THAN_OR_EQUALS:
      case FilterRule.LESS_THAN:
      case FilterRule.LESS_THAN_OR_EQUALS: {
        return `${this.queryName}${this.rule}${this.getTransformedValue()}`;
      }
      case FilterRule.IS_NULL:
      case FilterRule.IS_NOT_NULL: {
        const operator = this.rule === FilterRule.IS_NOT_NULL ? "!=" : "==";
        return `${this.queryName}${operator}null`;
      }
      case FilterRule.LIKE:
      case FilterRule.NOT_LIKE: {
        const operator =
          this.rule === FilterRule.NOT_LIKE
            ? FilterRule.NOT_EQUALS
            : FilterRule.EQUALS;
        return `${
          this.queryName
        }${operator}'=LIKE=${this.getTransformedValue()}=LIKE='`;
      }
      case FilterRule.IN:
      case FilterRule.NOT_IN: {
        const operator =
          this.rule === FilterRule.NOT_IN
            ? FilterRule.NOT_EQUALS
            : FilterRule.EQUALS;
        return `${this.queryName}${operator}[${this.getTransformedValue()}]`;
      }
      default:
        return "";
    }
  }
  //#endregion
  static override is(object: unknown): object is IChildFilter {
    return object instanceof ChildFilter;
  }
}
