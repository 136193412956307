import { RefObject, memo } from "react";
import {
  Switch,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableSelection,
  TableSelectionDomRef,
} from "@ui5/webcomponents-react";

import { ActivityEnum } from "../models/enums/ActivityEnum";
import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import SwitchDesign from "@ui5/webcomponents/dist/types/SwitchDesign";
import { WithPigeonHandlerProps } from "../hoc/withPigeonHandler/withPigeonHandlerProps";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import usePigeons from "../hooks/usePigeons";
import withPigeonHandler from "../hoc/withPigeonHandler/withPigeonHandler";

export interface PigeonTableProps {
  choosenSeason?: null | number;
  interactiveRow?: boolean;
  searchValue?: string;
  activity?: ActivityEnum;
}

const PigeonTable = ({
  choosenSeason,
  interactiveRow,
  searchValue,
  activity = ActivityEnum.All,
  onChangeActive,
}: PigeonTableProps & WithPigeonHandlerProps) => {
  const navigate = useNavigate();
  const { pigeons, lastMeta, fetchNextPage, busy } = usePigeons(
    new PageOptions({
      filters:
        `ring.number=='=LIKE=${searchValue}=LIKE='` +
        (activity === ActivityEnum.All
          ? ""
          : `&&isActive==${activity === ActivityEnum.Active}`),
    })
  );

  return (
    <Table
      overflowMode="Popin"
      loading={busy}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell width="80px">
            {getText("General.Active")}
          </TableHeaderCell>
          <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
          {!breakpoints.isPhone() && (
            <TableHeaderCell>{getText("General.PigeonName")}</TableHeaderCell>
          )}
          <TableHeaderCell width="80px">
            {getText("General.Sex")}
          </TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeon/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeons?.map((pigeon) => (
        <TableRow
          interactive={interactiveRow}
          rowKey={pigeon.id.toString()}
          id={pigeon.id.toString()}
          key={`pigeons-view-${pigeon.id}`}
        >
          <TableCell>
            <Switch
              design={SwitchDesign.Graphical}
              checked={pigeon.isActive}
              onChange={() => onChangeActive(pigeon)}
            />
          </TableCell>
          <TableCell>{pigeon.RingNumber}</TableCell>
          {!breakpoints.isPhone() && (
            <TableCell>{pigeon.ReadableName}</TableCell>
          )}
          <TableCell>{Formatter.PigeonSex(pigeon.sex)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default memo(withPigeonHandler(PigeonTable), (prev, next) => {
  return (
    prev.choosenSeason === next.choosenSeason &&
    prev.searchValue === next.searchValue &&
    prev.activity === next.activity
  );
});
