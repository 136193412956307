import { authDelete, authGet, authPost, authPut } from "./BaseService";

import { ITag } from "../models/Tag";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";

export const getTag = (id: number) => {
  return authGet<ITag>(`tag/id/${id}`);
};

export const getTags = async (options: PageOptions) => {
  const result = await authGet<object>(`tag?${options.getParams()}`);
  return new Page<ITag>().fillData(result.data);
};

export const getCotes = async (options: PageOptions, filters: string = "") => {
  options.filters = filters;
  const result = await authGet<Page<ITag>>(`tag/cotes?${options.getParams()}`);
  return new Page<ITag>().fillData(result.data);
};

export const createTag = async (tag: ITag) => {
  const data = await authPost<ITag>(`tag/`, tag);
  return data.data;
};

export const updateTag = async (tag: ITag) => {
  const data = await authPut<ITag>(`tag/${tag.id}`, tag);
  return tag;
};

export const setVisible = async (tagId: number, isVisible: boolean) => {
  return authPost(`tag/visible/${tagId}/${isVisible}`);
};

export const setDefault = async (tagId: number) => {
  return authPost(`tag/default/${tagId}`);
};

export const removeTag = async (id: number) => {
  const data = await authDelete<ITag>(`tag/${id}`);
  return data.data;
};
