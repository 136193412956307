import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import PageOptions from "../models/PageOptions";
import { getEggs } from "../services/EggService";

const useEggs = (options = new PageOptions()) => {
  const queryClient = useQueryClient();
  const queryFlights = useInfiniteQuery({
    queryKey: options.getQueryKey("eggs"),
    queryFn: ({ pageParam = 1 }) => getEggs(options.setPage(pageParam)),
    getNextPageParam: options.nextPagefn,
  });

  const invalidate = () => {
    queryClient.invalidateQueries(["eggs"]);
    queryClient.invalidateQueries(["egg"]);
  };

  return {
    eggs: queryFlights.data?.pages.flatMap((page) => page.data) || [],
    lastMeta: queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta,
    refetch: queryFlights.refetch,
    fetchNextPage: queryFlights.fetchNextPage,
    invalidate,
  };
};
export default useEggs;
