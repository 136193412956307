import { CreateFlightTag, ITag, Tag } from "./Tag";
import {
  addFlight,
  addToPrivateFlight,
  finishFlight,
  joinToFlight,
  rejectShareFlight,
  removeFlight,
  shareFlight,
  updateFlight,
} from "../services/FlightService";

import { Base } from "./Base";
import FlightHelper from "../utils/FlightHelper";
import { FlightMarker } from "./Marker";
import { FlightSharing } from "./FlightSharing";
import { FlightType } from "./enums/FlightType";
import { IUser } from "../services/IUserService";
import User from "./User";
import SelectOption from "./SelectOption";
import Formatter from "../utils/Formatter";
import { getText } from "../locales/initI18n";
import { FlightFilterEnum } from "./enums/FlightFilterEnum";

export class Flight extends Base {
  tab?: FlightFilterEnum;
  name = "";
  remarks = "";
  createDate: Date = new Date();
  updateDate: Date = new Date();
  startDate: Date | string = new Date();
  endDate!: string; // Date | string;
  userEndDate!: string;
  coordinates: FlightMarker[] = [];
  start = new Tag();
  end = new Tag();
  user = new User();
  userId: number = 0;
  type = FlightType.Single;
  parentFlightId: number = 0;
  parentFlight!: Flight | null;
  /**
   * Related flights are the flights that are part of the same event.
   * For example, if a user creates a private event, the related flights are the flights that are part of the event.
   * @info **For getter use** `getRelatedFlights()`
   * @info For checking if there are related flights use `isRelatedFlights()`
   */
  relatedFlights: Flight[] = [];
  sharing: FlightSharing[] = [];

  override isNew(): boolean {
    if (!this.id) return true;
    if (
      this.id &&
      this.type === FlightType.Single &&
      (this.start.isNew() || this.end.isNew())
    )
      return true;
    return false;
  }

  fillData(data: object): this {
    super.fillData(data);

    this.start = Tag.create(this.start);
    this.end = Tag.create(this.end);
    this.relatedFlights = this.relatedFlights.map((flight) =>
      Flight.create(flight)
    );
    this.sharing = this.sharing.map((sharing) => FlightSharing.create(sharing));
    this.parentFlight = this.parentFlight
      ? Flight.create(this.parentFlight)
      : null;

    return this;
  }

  getNewName(releaseOrDate: Tag | Date, date?: Date) {
    if (releaseOrDate instanceof Date) {
      const releaseName = this.name.split(" | ")[0];
      return `${releaseName} | ${Formatter.Date(releaseOrDate)}`;
    }
    const release = releaseOrDate as Tag;
    if (!date) {
      const date = this.name.split(" | ")[1] || "";
      return `${release.name} | ${date}`;
    }
    return `${release.name} | ${Formatter.Date(date)}`;
  }

  async save() {
    if (!this.isValid()) {
      throw new Error("FLIGHT_NOT_VALID");
    }
    return addFlight(this);
  }

  getRelatedFlights() {
    if (this.parentFlight) {
      if (this.parentFlight.type === FlightType.Single) {
        return [this.parentFlight, ...this.parentFlight.relatedFlights];
      }
      return this.parentFlight.relatedFlights;
    }
    return this.relatedFlights;
  }

  isRelatedFlights() {
    return (
      this.relatedFlights.length > 0 ||
      (this.parentFlight?.relatedFlights?.length ?? 0) > 0
    );
  }

  isPrivateEvent() {
    return FlightHelper.isPrivateEvent(this);
  }

  isValid() {
    if (this.type === FlightType.Single) {
      return (
        this.name && this.startDate && !this.start.isNew() && !this.end.isNew()
      );
    }
    return this.name && this.startDate && !this.start.isNew();
  }

  isChild() {
    return !!this.parentFlightId;
  }

  isJoinable(user: IUser) {
    return FlightHelper.showJoin(this, user?.id) && !this.isSharedTab();
  }

  isEventOwner(user: IUser) {
    return FlightHelper.isCurrentUserEvent(this, user?.id);
  }

  isShared(user: IUser) {
    return FlightHelper.isSharing(this, user?.id);
  }

  isSharedTab() {
    return this.tab === FlightFilterEnum.Shared;
  }

  isOwner(user: IUser) {
    return this.user.id === user.id;
  }

  isFinished() {
    return !!this.userEndDate;
  }

  isHistory() {
    const startDate = new Date(this.startDate);
    return startDate < Flight.LimitPresentDateStart();
  }

  isPresent() {
    const startDate = new Date(this.startDate);
    return (
      startDate >= Flight.LimitPresentDateStart() &&
      startDate <= Flight.LimitPresentDateEnd()
    );
  }

  isEvent(): boolean {
    if (this.parentFlight) return this.parentFlight.isEvent();
    return this.type === FlightType.Open;
  }

  isEventPermision(user: IUser) {
    return FlightHelper.isEventEditPermission(this.parentFlight, user?.id);
  }

  isSingle() {
    return this.type === FlightType.Single;
  }

  canCreateEvent(user: IUser) {
    return (
      FlightHelper.isCurrentUserSingle(this, user?.id) &&
      !FlightHelper.isPrivateEventChild(this) &&
      !FlightHelper.isEventChild(this)
    );
  }

  canBeRender() {
    return !!(this.id && !this.start.isNew() && !this.end.isNew());
  }

  canBeEdit(user: IUser) {
    return FlightHelper.isEditable(this, user.id) && !this.isSharedTab();
  }

  async update() {
    if (this.isNew()) {
      throw new Error("CANNOT_UPDATE_FLIGHT");
    }
    return updateFlight(this);
  }

  async delete() {
    if (this.isNew()) {
      throw new Error("Cannot delete a new flight.");
    }
    return removeFlight(this.id);
  }

  async finish(endDate: string) {
    return finishFlight(this.id, endDate);
  }

  async finishParentFlight(endDate: string) {
    if (this.parentFlight?.isNew()) {
      throw new Error("Cannot finish parent flight.");
    }
    if ((this.parentFlight && this.parentFlight.type) === FlightType.Open) {
      return this.parentFlight?.finish(endDate);
    }
    if (!this.parentFlight && this.type === FlightType.Open) {
      return this.finish(endDate);
    }
  }

  async join(): Promise<Flight> {
    if (this.isNew()) throw new Error("Cannot join a new flight.");
    if (this.type !== FlightType.Open)
      throw new Error("Cannot join a private flight.");
    return joinToFlight({ flightId: this.id, end: this.end });
  }

  async removeShare(shared: FlightSharing) {
    this.sharing = this.sharing.filter((sharing) => sharing.id !== shared.id);
    await this.share(this.sharing.map((sharing) => sharing.user));
    return this.sharing;
  }

  async rejectShare(user: IUser) {
    return rejectShareFlight(this.id, user.id);
  }

  async removeFromPrivateEvent(flight: Flight) {
    this.relatedFlights = this.relatedFlights.filter(
      (relatedFlight) => relatedFlight.id !== flight.id
    );
    const userIds = this.relatedFlights.map(
      (relatedFlight) => relatedFlight.user
    );
    await this.addToPrivateEvent(this.user, userIds);
    return this.relatedFlights;
  }

  async share(users: IUser[]) {
    if (this.isNew()) throw new Error("Cannot share a new flight.");
    if (this.type !== FlightType.Single)
      throw new Error("Cannot share an event flight.");
    const userIds = users.map((user) => user.id);
    return shareFlight(this.id, userIds);
  }

  async addToPrivateEvent(owner: IUser, farmers: IUser[]) {
    if (!this.canCreateEvent(owner))
      throw new Error("Cannot add to private event");
    if (this.isNew()) throw new Error("CANNOT_UPDATE_FLIGHT");
    const userIds = farmers.map((farmer) => farmer.id);
    return addToPrivateFlight(this.id, userIds);
  }

  override toJSON(): object {
    return {
      ...super.toJSON(),
      tab: undefined,
    };
  }

  static getTypes() {
    const options = [
      new SelectOption({
        key: FlightType.Single,
        text: getText("FlightHighlightedInfo.Private"),
        icon: "locked",
      }),
      new SelectOption({
        key: FlightType.Open,
        text: getText("FlightHighlightedInfo.Competition"),
        icon: "globe",
      }),
    ];
    return options;
  }

  static LimitPresentDateStart() {
    const firstDate = new Date();
    firstDate.setDate(firstDate.getDate() - 1);
    firstDate.setHours(0, 0, 0, 0);
    return firstDate;
  }

  static LimitPresentDateEnd() {
    const secondDate = new Date();
    secondDate.setDate(secondDate.getDate() + 7);
    secondDate.setHours(0, 0, 0, 0);
    return secondDate;
  }
}

export interface CreateFlight {
  id?: number;
  name: string;
  remarks: string;
  startDate: Date | string;
  start: CreateFlightTag;
  end: CreateFlightTag;
  type: FlightType;
}

export interface JoinFlight {
  flightId?: number;
  end: CreateFlightTag;
}
