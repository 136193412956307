import { useContext } from "react";
import { ToastContext } from "../hoc/withToast/withToast";
import WithToastProps from "../hoc/withToast/WithToastProps";

const useToast = (): WithToastProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export default useToast;
