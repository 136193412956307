import { authDelete, authGet, authPost, get } from "./BaseService";

import { IFarmer } from "./IFollowService";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import User from "../models/User";

export const getFollowers = () => {
  return get(`follower/followers`);
};

export const getFollowing = () => {
  return get(`follower/following`);
};

export const follow = (id: number) => {
  return authPost(`follower/follow/${id}`);
};

export const unfollow = (id: number) => {
  return authDelete(`follower/follow/${id}`);
};

export const getAllFarmers = async (options: PageOptions) => {
  const result = await authGet<object>(
    `follower/allUsers?${options.getParams()}`
  );

  return new Page<IFarmer>().fillData(result.data);
};

export const getFriends = async (options: PageOptions) => {
  const result = await authGet<object>(
    `follower/Friends?${options.getParams()}`
  );
  return new Page<User>().fillData(result.data);
};

export const getFarmer = async (userId: number) => {
  const farmer = await authGet<IFarmer>(`follower/farmer/${userId}`);
  return farmer.data;
};
