import {
  Button,
  MessageBoxAction,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";
import {
  getAssignmentByYear,
  removeAssignment,
} from "../services/PigeonSeasonAssignmentService";

import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import PigeonSeasonAssignment from "../models/PigeonSeasonAssignment";
import TableOverflowMode from "@ui5/webcomponents/dist/types/TableOverflowMode";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import usePigeonAssignments from "../hooks/usePigeonAssignments";
import { useQuery } from "@tanstack/react-query";
import withPigeonSeasonAssignmentHandler from "../hoc/withPigeonSeasonAssignment/withPigeonSeasonAssignmentHandler";
import { withPigeonSeasonAssignmentHandlerProps } from "../hoc/withPigeonSeasonAssignment/withPigeonSeasonAssignmentHandlerProps";
import useMessage from "../hooks/useMessage";

export interface PigeonAssignmentCardProps {
  slot?: string;
  year?: number | string;
  pigeon: Pigeon;
}
const PigeonAssignmentCard = ({
  year,
  pigeon,
  slot,
  onChangeGroup,
}: PigeonAssignmentCardProps & withPigeonSeasonAssignmentHandlerProps) => {
  const { openMessage } = useMessage();
  const { data: assignment } = useQuery({
    queryKey: ["pigeon-season-assignments", pigeon.id, year],
    queryFn: () =>
      year ? getAssignmentByYear(pigeon, year?.toString() || "") : null,
  });
  const { assignments, invalidate, lastMeta, fetchNextPage, busy } =
    usePigeonAssignments(
      new PageOptions({
        filters: !year ? `pigeon.id==${pigeon.id}` : undefined,
      })
    );
  const dispatch = useAppDispatch();
  const onDelete = async (assignment: PigeonSeasonAssignment) => {
    const confirm = await openMessage(
      `Czy na pewno chcesz usunąć gołębia: ${pigeon.RingNumber} z sezonu ${assignment.season.year}?`
    );

    if (confirm !== MessageBoxAction.OK) return;

    try {
      await removeAssignment(assignment.id);
      invalidate();
    } catch (e) {
      dispatch(setError(e));
    }
  };
  if (year && !assignment) return null;
  const assignmentsToShow = year ? [assignment] : assignments;
  return (
    <Table
      slot={slot}
      overflowMode={TableOverflowMode.Popin}
      loading={busy}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>Sezon</TableHeaderCell>
          <TableHeaderCell>Grupa</TableHeaderCell>
          <TableHeaderCell width="300px"></TableHeaderCell>
          <TableHeaderCell width="200px"></TableHeaderCell>
        </TableHeaderRow>,
      ]}
    >
      {lastMeta?.isNext && !year && (
        <TableGrowing
          type="Button"
          growing-text="More"
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {assignmentsToShow?.map((assignment) => (
        <TableRow key={`PigeonAssignmentCard-${pigeon.id}-${assignment?.id}`}>
          <TableCell>{assignment?.season.year}</TableCell>
          <TableCell>{assignment?.ReadableGroup}</TableCell>
          <TableCell>
            <Button
              onClick={() => assignment && onChangeGroup(assignment)}
              icon="edit"
              design={ButtonDesign.Emphasized}
            >
              Zmień grupę
            </Button>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => assignment && onDelete(assignment)}
              icon="delete"
              design={ButtonDesign.Negative}
            >
              Usuń z sezonu
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default withPigeonSeasonAssignmentHandler(PigeonAssignmentCard);
