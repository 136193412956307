import { authDelete, authGet, authPatch, authPost } from "./BaseService";

import BaseStatistics from "../models/BaseStatistics";
import ChildFilter from "../models/Filter/ChildFilter";
import { FilterName } from "../models/enums/FilterName";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import ParentFilter from "../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../models/Filter/ParentFilterOperator";
import Pigeon from "../models/Pigeon";
import PigeonAchievement from "../models/PigeonAchievement";

export const getPigeons = async (options: PageOptions) => {
  const result = await authGet<object[]>(`pigeon?${options.getParams()}`);

  const page = new Page<Pigeon>().fillData(result.data);
  page.data = page.data.map((item) => Pigeon.create(item));
  return page;
};

export const getPigeon = async (id?: number | string) => {
  const data = await authGet<object>(`pigeon/${id}`);
  return new Pigeon().fillData(data.data);
};

export const getPigeonByRing = async (ring: string) => {
  const data = await authGet<object>(`pigeon/ring/${ring}`);
  return new Pigeon().fillData(data.data);
};

export const getPedigree = async (id: number, maxLevel: number) => {
  const data = await authGet<object>(`pigeon/pedigree/${id}/${maxLevel}`);
  return new Pigeon().fillData(data.data);
};

export const addPigeon = async (pigeon: Pigeon) => {
  const data = await authPost<{ data: object }>("pigeon", pigeon);
  return Pigeon.create(data.data);
};

export const updatePigeon = (pigeon: Pigeon) => {
  return authPatch<object>(`pigeon/${pigeon.id}`, pigeon.toJSON());
};

export const removePigeon = (pigeon: Pigeon) => {
  return authDelete<object>(`pigeon/${pigeon.id}`);
};

export const removeFather = (pigeon: Pigeon) => {
  pigeon.removeFather();
  return updatePigeon(pigeon);
};

export const removeMother = (pigeon: Pigeon) => {
  pigeon.removeMother();
  return updatePigeon(pigeon);
};

export const getAchievements = async (
  pigeon: Pigeon,
  useInPedigree?: boolean
) => {
  let childFilters: ChildFilter[] = [];
  childFilters.push(new ChildFilter(FilterName.PigeonId, pigeon.id));
  if (useInPedigree !== undefined) {
    childFilters.push(new ChildFilter(FilterName.UseInPedigree, useInPedigree));
  }
  const filter = new ParentFilter(childFilters, ParentFilterOperator.And);
  const result = await authGet<object[]>(
    "pigeon-achievement?filters=" + filter.transform()
  );
  return result.data.map((achievement) =>
    new PigeonAchievement().fillData(achievement)
  );
};

export const addAchievement = (achievement: PigeonAchievement) => {
  return authPost<object>("pigeon-achievement", achievement);
};

export const updateAchievement = (achievement: PigeonAchievement) => {
  return authPatch<object>(`pigeon-achievement/${achievement.id}`, achievement);
};

export const removeAchievement = (achievement: PigeonAchievement) => {
  return authDelete<object>(`pigeon-achievement/${achievement.id}`);
};

export const getPigeonsStatistics = async () => {
  const result = await authGet<object>("pigeon/statistics");
  return BaseStatistics.create(result.data);
};

export const getUserPigeonsStatistics = async (userId: number) => {
  const result = await authGet<object>(`pigeon/statistics/${userId}`);
  return BaseStatistics.create(result.data);
};
