import { getI18n, initReactI18next } from "react-i18next";

import en from "./en.json";
import getUserLocale from "get-user-locale";
import i18n from "i18next";
import nl from "./nl.json";
import pl from "./pl.json";

export const userLocale = getUserLocale() || undefined;

export const initi18n = () => {
  i18n.use(initReactI18next).init({
    resources: {
      nl: {
        translation: nl,
      },
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
    lng: userLocale,
    fallbackLng: "en", // This is the default language if none of the users preffered languages are available
    interpolation: {
      escapeValue: false, // https://www.i18next.com/translation-function/interpolation#unescape
    },
    returnNull: false,
  });
};

export const getText = (
  key: string,
  params?: Record<string | number, string | number | undefined | null>
) => {
  if (!params) return getI18n().t(key) || key;
  return getI18n().t(key, params) || key;
};
