import { HBox, VBox } from "../../styles/Flexbox.styled";
import generatePDF, { Margin, usePDF } from "react-to-pdf";
import { getSummaryWeather, getWeather } from "../../services/WeatherService";
import { useRef, useState } from "react";

import Busy from "../Busy";
import { Button } from "@ui5/webcomponents-react";
import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import Confirm from "../modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../../models/enums/ConfirmModalTypeEnum";
import Formatter from "../../utils/Formatter";
import HighligtedText from "../HighlightedText/HighlightedText";
import { IPDFFlight } from "./IPDFFlight";
import Logo from "../Logo/Logo";
import ObjectIdentifier from "../ObjectIdentifier";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { distance } from "../../utils/utils";
import { formatDateTime } from "../../utils/VariableHelper";
import { getText } from "../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";

const PDFFlight = ({ flight, children }: IPDFFlight) => {
  const theme = useTheme();
  const targetRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const { data: weatherStart } = useQuery({
    queryKey: ["weather", flight.start.latitude, flight.start.longitude],
    queryFn: () => getWeather(flight.start, new Date(flight.startDate)),
    enabled: isGenerating,
  });

  const { data: weatherEnd } = useQuery({
    queryKey: ["weather", flight.end.latitude, flight.end.longitude],
    queryFn: () => getWeather(flight.end, new Date(flight.endDate)),
    enabled: isGenerating,
  });

  const { data: weatherSummary } = useQuery({
    queryKey: ["weather", flight?.id],
    queryFn: () => getSummaryWeather(flight.id),
    enabled: isGenerating,
  });

  const Generate = () => {
    setIsConfirm(false);
    setIsGenerating(true);
    setTimeout(async () => {
      const test = await generatePDF(targetRef, {
        method: "build",
        filename: `${flight.name}.pdf`,
      });
      downloadPDF(test.output("blob"));
      setIsGenerating(false);
    }, 4000);
  };

  function downloadPDF(blob: Blob) {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = flight.name + ".pdf";
    link.click();
  }

  if (isConfirm) {
    return (
      <Confirm
        type={ConfirmModalTypeEnum.Warn}
        isVisible={true}
        title={getText("PDFFlight.GenerateDocumentTitle")}
        description={getText("PDFFlight.GenerateDocumentMessage")}
        onConfirm={Generate}
        onCancel={() => setIsGenerating(false)}
      />
    );
  }

  if (!isGenerating && !children) {
    return (
      <Button
        design="Transparent"
        onClick={() => setIsConfirm(true)}
        icon="document-text"
      >
        {getText("PDFFlight.GenerateDocumentTitle")}
      </Button>
    );
  }

  if (!isGenerating && !!children) {
    return <ButtonOpacity onClick={Generate}>{children}</ButtonOpacity>;
  }

  return (
    <>
      <VBox
        visible={isGenerating}
        position="fixed"
        width="100%"
        height="100%"
        Top="0"
        Left="0"
        zIndex={5}
      >
        <Busy
          visible={true}
          opacity={1}
          text={getText("PDFFlight.WaitForGenerate")}
        />
        <VBox
          ref={targetRef}
          width="210mm"
          height="290mm"
          Margin="20px"
          Padding="20px"
          justifyContent="space-between"
        >
          <VBox>
            <HBox justifyContent="flex-end">
              <HighligtedText
                text={`${getText(
                  "PDFFlight.GenerateDate"
                )} ${new Date().toLocaleDateString()}`}
              />
            </HBox>
            <VBox Margin="0 0 20px 0">
              <Text type={TextTypeEnum.h2} size={TextSizeEnum.large}>
                {getText("PDFFlight.Title")}
              </Text>
              <Text type={TextTypeEnum.h4} size={TextSizeEnum.large}>
                {flight.name}
              </Text>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.large}
                weight={TextWeightEnum.regular}
              >
                {flight.remarks}
              </Text>
            </VBox>
            <HBox
              Margin="0 0 20px 0"
              Padding="20px"
              BorderRadius="20px"
              backgroundColor={theme.palette.Grey5}
              gap="50px"
              alignItems="flex-end"
            >
              <VBox gap="10px" width="250px">
                <Text
                  type={TextTypeEnum.h4}
                  size={TextSizeEnum.large}
                  Margin="0 0 10px 0"
                  width="100%"
                >
                  {getText("StartingPosition")}
                </Text>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.NameOfRelease")}
                    text={flight.start.name}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.StartDate")}
                    text={formatDateTime(flight.startDate)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.GeoPlaceOfRelease")}
                    text={`${flight.start.latitude}, ${flight.start.longitude}`}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature")}
                    text={Formatter.Temperature(weatherStart?.tempSurface)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature100m")}
                    text={Formatter.Temperature(weatherStart?.temp100m)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Liquid")}
                    text={Formatter.Liquid(weatherStart?.liquid)}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherStart?.windSurface,
                      weatherStart?.WindDirectionSurface
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed100m")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherStart?.wind100m,
                      weatherStart?.WindDirection100m
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier title="" text="" />
                </VBox>
              </VBox>
            </HBox>
            <HBox
              Margin="0 0 20px 0"
              Padding="20px"
              BorderRadius="20px"
              backgroundColor={theme.palette.Grey5}
              gap="50px"
              alignItems="flex-end"
            >
              <VBox gap="10px" width="250px">
                <Text
                  type={TextTypeEnum.h4}
                  size={TextSizeEnum.large}
                  Margin="0 0 10px 0"
                >
                  {getText("EndingPosition")}
                </Text>
                <VBox>
                  <ObjectIdentifier
                    title="Nazwa gołebnika"
                    text={flight.end.name}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.DateEndFlight")}
                    text={formatDateTime(flight.endDate)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.GeoCotePlace")}
                    text={`${flight.end.latitude}, ${flight.end.longitude}`}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature")}
                    text={Formatter.Temperature(weatherEnd?.tempSurface)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature100m")}
                    text={Formatter.Temperature(weatherEnd?.temp100m)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Liquid")}
                    text={Formatter.Liquid(weatherEnd?.liquid)}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherEnd?.windSurface,
                      weatherEnd?.WindDirectionSurface
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed100m")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherEnd?.wind100m,
                      weatherEnd?.WindDirection100m
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier title="" text="" />
                </VBox>
              </VBox>
            </HBox>
            <HBox
              Padding="20px"
              BorderRadius="20px"
              backgroundColor={theme.palette.Grey5}
              gap="50px"
              alignItems="flex-end"
            >
              <VBox gap="10px" width="250px">
                <Text
                  type={TextTypeEnum.h4}
                  size={TextSizeEnum.large}
                  Margin="0 0 10px 0"
                >
                  {getText("Weather")}
                </Text>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature")}
                    text={Formatter.Temperature(weatherSummary?.tempSurface)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Temperature100m")}
                    text={Formatter.Temperature(weatherSummary?.temp100m)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Liquid")}
                    text={Formatter.Liquid(weatherSummary?.liquid)}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherSummary?.windSurface,
                      weatherSummary?.WindDirectionSurface
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("WindSpeed100m")}
                    text={Formatter.WindSpeedAndDirection(
                      weatherSummary?.wind100m,
                      weatherSummary?.WindDirection100m
                    )}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Pressure")}
                    text={Formatter.Pressure(weatherSummary?.pressure)}
                  />
                </VBox>
              </VBox>
              <VBox gap="10px" width="250px">
                <VBox>
                  <ObjectIdentifier
                    title={getText("Humidity")}
                    text={Formatter.Humidity(weatherSummary?.humidity)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("Visibility")}
                    text={Formatter.Visibility(weatherSummary?.visibility)}
                  />
                </VBox>
                <VBox>
                  <ObjectIdentifier
                    title={getText("PDFFlight.PathLength")}
                    text={Formatter.Distance(
                      distance(flight.start, flight.end)
                    )}
                  />
                </VBox>
              </VBox>
            </HBox>
          </VBox>
          <HBox justifyContent="flex-end" alignItems="center">
            <VBox>
              <Text
                type={TextTypeEnum.h4}
                weight={TextWeightEnum.bold}
                size={TextSizeEnum.large}
              >
                Pigeon Map
              </Text>
              <Text type={TextTypeEnum.h5} size={TextSizeEnum.small}>
                Digging Barbara Skrzypnik
              </Text>
            </VBox>
            <Logo imgOnly />
          </HBox>
        </VBox>
      </VBox>
    </>
  );
};

export default PDFFlight;
