import {
  Button,
  DynamicPage,
  DynamicPageTitle,
  Form,
  FormGroup,
  FormItem,
  MultiInput,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
  Title,
  Token,
  Toolbar,
  ToolbarButton,
} from "@ui5/webcomponents-react";
import { memo, useState } from "react";

import DialogResult from "../models/DialogResult";
import { EggState } from "../models/enums/EggStateEnum";
import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import PigeonPair from "../models/PigeonPair";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";
import WithEggHandlerProps from "../hoc/withEggHandler/withEggHandlerProps";
import { WithPigeonPairHandlerProps } from "../hoc/WithPigeonPairHandler/WithPigeonPairHandlerProps";
import { getText } from "../locales/initI18n";
import useEggs from "../hooks/useEggs";
import { useNavigate } from "react-router-dom";
import withEggHandler from "../hoc/withEggHandler/withEggHandler";
import withPigeonPairHandler from "../hoc/WithPigeonPairHandler/WithPigeonPairHandler";
import BaseDialogProps from "../models/BaseDialogProps";
import EggInformation, { EggInformationProps } from "./modals/EggInformation";
import VerticalAd from "./Ads/VerticalAd";

export interface PigeonPairPageProps {
  slot?: string;
  pair?: PigeonPair;
  year: number;
  openSelectPigeon: (initFitelrs: string) => Promise<DialogResult>;
  onClose: () => void;
}

const PigeonPairPage = ({
  slot,
  pair,
  year,
  onClose,
  openSelectPigeon,
  onAddEgg,
  onAddPigeonPair,
  onHatchEgg,
  onRemovePigeonPair,
  openDialog,
}: PigeonPairPageProps &
  WithPigeonPairHandlerProps &
  WithEggHandlerProps &
  WithDialogsProps) => {
  const navigate = useNavigate();
  const [male, setMale] = useState<Pigeon | null>(null);
  const [female, setFemale] = useState<Pigeon | null>(null);
  const { eggs, lastMeta, fetchNextPage } = useEggs(
    new PageOptions({ filters: `pigeonPair.id==${pair?.id}` })
  );
  const onSelectMale = async () => {
    const result = await openSelectPigeon("sex == 0 && isActive == true");
    if (result.isClose()) return;
    setMale(result.getData<Pigeon>());
  };
  const onSelectFemale = async () => {
    const result = await openSelectPigeon("sex == 1 && isActive == true");
    if (result.isClose()) return;
    setFemale(result.getData<Pigeon>());
  };
  return (
    <DynamicPage
      headerPinned={true}
      hidePinButton={true}
      slot={slot}
      style={{
        width: "100%",
      }}
      titleArea={
        <DynamicPageTitle
          actionsBar={
            !!pair && (
              <Toolbar design="Transparent">
                <ToolbarButton
                  design="Emphasized"
                  icon="add"
                  text={getText("PigeonPair.AddEgg")}
                  onClick={() => onAddEgg(pair)}
                />
                <ToolbarButton
                  onClick={() => onRemovePigeonPair(pair)}
                  icon="delete"
                  design="Negative"
                  text={getText("PigeonPair.Remove")}
                />
              </Toolbar>
            )
          }
          heading={
            !pair ? (
              <Title>{getText("PigeonPair.PairPigeons")}</Title>
            ) : (
              <Title>{getText("PigeonPair.Pair")}</Title>
            )
          }
          snappedHeading={
            !pair ? (
              <Title>{getText("PigeonPair.PairPigeons")}</Title>
            ) : (
              <Title>{getText("PigeonPair.Pair")}</Title>
            )
          }
          subheading={
            !pair && <Text>{getText("PigeonPair.SelectMaleFemale")}</Text>
          }
          snappedSubheading={
            !pair && <Text>{getText("PigeonPair.SelectMaleFemale")}</Text>
          }
          navigationBar={
            <Toolbar design="Transparent">
              <ToolbarButton
                design="Transparent"
                icon="decline"
                onClick={onClose}
              />
            </Toolbar>
          }
        ></DynamicPageTitle>
      }
    >
      <Form>
        <FormGroup>
          <FormItem labelContent={<span>{getText("PigeonPair.Male")}</span>}>
            {pair ? (
              <Text>{pair.maleRingNumber}</Text>
            ) : (
              <MultiInput
                showValueHelpIcon
                onClick={onSelectMale}
                tokens={male ? <Token text={male.ring.number} /> : undefined}
              />
            )}
          </FormItem>
          <FormItem labelContent={<span>{getText("PigeonPair.Female")}</span>}>
            {pair ? (
              <Text>{pair.femaleRingNumber}</Text>
            ) : (
              <MultiInput
                showValueHelpIcon
                onClick={onSelectFemale}
                tokens={
                  female ? <Token text={female.ring.number} /> : undefined
                }
              />
            )}
          </FormItem>
          {!pair && (
            <FormItem>
              <Button
                design="Emphasized"
                onClick={() => onAddPigeonPair(male, female, year)}
              >
                {getText("PigeonPair.PairPigeons")}
              </Button>
            </FormItem>
          )}
        </FormGroup>
      </Form>
      {!!pair && (
        <Table
          overflowMode="Popin"
          headerRow={[
            <TableHeaderRow>
              <TableHeaderCell minWidth="200px">
                {getText("General.RingNumber")}
              </TableHeaderCell>
              <TableHeaderCell minWidth="200px">
                {getText("PigeonPair.LayingDate")}
              </TableHeaderCell>
              <TableHeaderCell width="100px">
                {getText("PigeonPair.Status")}
              </TableHeaderCell>
              <TableHeaderCell width="100px">
                {getText("PigeonPair.Brood")}
              </TableHeaderCell>
              <TableHeaderCell width="100px"></TableHeaderCell>
              <TableHeaderCell width="60px"></TableHeaderCell>
            </TableHeaderRow>,
          ]}
          onRowClick={(event) => {
            const key = event.detail.row.rowKey;
            if (!key) return;
            openDialog("EggInformation", { eggId: key });
            // navigate(`/dashboard/pigeon/${key}`);
          }}
        >
          {lastMeta?.isNext && (
            <TableGrowing
              type="Button"
              growing-text={getText("General.More")}
              slot="features"
              onLoadMore={() => fetchNextPage()}
            ></TableGrowing>
          )}
          {eggs?.map((egg) => (
            <TableRow
              interactive
              rowKey={egg.id?.toString()}
              id={egg.id.toString()}
              key={`pigeon-pair-egg-${egg.id}`}
            >
              <TableCell>{egg.pigeon?.RingNumber || "-"}</TableCell>
              <TableCell>{Formatter.DateTime(egg.dateOfLaying)}</TableCell>
              <TableCell>{egg.ReadableStatus}</TableCell>
              <TableCell>{egg.brood}</TableCell>
              <TableCell>
                {egg.isFertile && (
                  <Button onClick={() => onHatchEgg(egg)}>Wykluj</Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}
      <VerticalAd name="PigeonPairPage" />
    </DynamicPage>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "EggInformation": {
      return (
        <EggInformation
          {...(props as EggInformationProps)}
          onClose={() => onClose(DialogResult.Close())}
        />
      );
    }
  }
};

export default memo(
  withEggHandler(
    withPigeonPairHandler(withDialogs(PigeonPairPage, renderDialogs))
  )
);
