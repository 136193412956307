import { useQuery, useQueryClient } from "@tanstack/react-query";

import Egg from "../models/Egg";
import { getEgg } from "../services/EggService";
import { useEffect } from "react";
import { useHookstate } from "@hookstate/core";

const useEgg = (id?: number | string) => {
  const queryClient = useQueryClient();
  const state = useHookstate({ egg: new Egg() });
  const getEggInternal = async () => {
    const e = await getEgg(id);
    state.egg.set(e);
    return e;
  };
  const query = useQuery(["egg", id], getEggInternal);

  const egg = state.get({ noproxy: true }).egg as Egg;

  useEffect(() => {
    if (egg?.id !== id && query.isFetched && query.data) {
      state.egg.set(query.data);
    }
  }, [query.data, egg.id, query.isFetched]);

  return { egg: egg, setter: state.egg, refetch: query.refetch };
};

export default useEgg;
