import { Base } from "./Base";
import Pigeon from "./Pigeon";
import Season from "./Season";
import { SeasonAssignmentGroup } from "./enums/SeasonAssignmentGroup";
import User from "./User";
import { getText } from "../locales/initI18n";

export default class PigeonSeasonAssignment extends Base {
  pigeonId!: number;
  season = new Season();
  user!: User;
  group!: SeasonAssignmentGroup;
  classification!: string;
  pigeon!: Pigeon;

  fillData(data: object): this {
    super.fillData(data);
    this.pigeon = Pigeon.create(this.pigeon);
    this.season = Season.create(this.season);
    this.user = User.create(this.user);
    return this;
  }

  get ReadableGroup(): string {
    switch (this.group) {
      case SeasonAssignmentGroup.Old:
        return getText("Pigeon.group.Old");
      case SeasonAssignmentGroup.Young:
        return getText("Pigeon.group.Young");
      case SeasonAssignmentGroup.Breeding:
        return getText("Pigeon.group.Breeding");
      default:
        return getText("Pigeon.group.Unknown");
    }
  }
}
