import { HBox, VBox } from "../../styles/Flexbox.styled";

import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const FourthNews = () => {
  const theme = useTheme();
  const goTo = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("FourthNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox gap="20px">
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FourthNews.ComputerFlightsTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FourthNews.ComputerFlightsDescription")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/FourthNewsComputerFlights.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("FourthNews.ComputerFlightsDescriptionCaption")}
            </Text>
          </VBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FourthNews.PigeonsChangesTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FourthNews.PigeonsChangesDescripiton")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/FourthNewsComputerPigeons.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("FourthNews.PigeonsChangesDescripitonCaption")}
            </Text>
          </VBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FourthNews.NewestVideosTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FourthNews.NewestVideosDescription")}
          </Text>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              style={{ zoom: 0.5 }}
              src="https://www.youtube.com/embed/DHQYPrhLBC4?si=2WWa1TtRKBmadmjm"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </HBox>
        </VBox>
      </VBox>
    </VBox>
  );
};

export default FourthNews;
