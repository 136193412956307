import {
  Button,
  ObjectStatus,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";
import EggInformation, { EggInformationProps } from "./modals/EggInformation";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import BaseDialogProps from "../models/BaseDialogProps";
import DialogResult from "../models/DialogResult";
import { EggState } from "../models/enums/EggStateEnum";
import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import WithEggHandlerProps from "../hoc/withEggHandler/withEggHandlerProps";
import { getText } from "../locales/initI18n";
import useEggs from "../hooks/useEggs";
import { useNavigate } from "react-router-dom";
import withEggHandler from "../hoc/withEggHandler/withEggHandler";

export interface PigeonSeasonEggTableProps {
  choosenSeason?: null | number;
  interactiveRow?: boolean;
}

const PigeonSeasonEggTable = ({
  choosenSeason,
  onHatchEgg,
  openDialog,
}: PigeonSeasonEggTableProps & WithEggHandlerProps & WithDialogsProps) => {
  const navigate = useNavigate();
  const { eggs, fetchNextPage, lastMeta } = useEggs(
    new PageOptions({ filters: `pigeonPair.season.year==${choosenSeason}` })
  );
  const navigateToPigeon = (id: number) => {
    navigate(`/dashboard/pigeons/${choosenSeason}/${id}`);
  };
  return (
    <Table
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.Male")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.Female")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.LayingDate")}</TableHeaderCell>
          <TableHeaderCell>{getText("AddEgg.Status")}</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={async (event) => {
        const key = event.detail.row.rowKey;
        const result = await openDialog("EggInformation", { eggId: key });
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {eggs?.map((egg) => (
        <TableRow
          interactive
          rowKey={egg.id.toString()}
          id={egg.id.toString()}
          key={`PigeonSeasonEggTable-${egg.id}`}
        >
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(egg.pigeonPair.male.id)}
              interactive
              inverted
            >
              {egg.pigeonPair.maleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(egg.pigeonPair.female.id)}
              interactive
              inverted
            >
              {egg.pigeonPair.femaleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>{Formatter.DateTime(egg.dateOfLaying)}</TableCell>
          <TableCell>{egg.ReadableStatus}</TableCell>
          <TableCell>
            {egg.status === EggState.Fertile && (
              <Button onClick={() => onHatchEgg(egg)}>Wykluj</Button>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "EggInformation": {
      return (
        <EggInformation
          {...(props as EggInformationProps)}
          onClose={() => onClose(DialogResult.Close())}
        />
      );
    }
  }
};

export default withEggHandler(withDialogs(PigeonSeasonEggTable, renderDialogs));
