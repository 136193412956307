import Ad from "../models/Ad";
import { AdType } from "../models/enums/AdType";
import { authGet, authPost, get } from "./BaseService";

export const getAd = async (type: AdType) => {
  const data = await authGet<object>(`ads/random/${type}`);
  const ad = Ad.create(data.data);

  if (!ad.md) return null;

  return ad;
};

export const incrementAdClick = async (clickedAd: string) => {
  await authPost<object>(
    `user-statistics/increment-ad-click/${encodeURIComponent(clickedAd)}`
  );
};
