import {
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import PageOptions from "../models/PageOptions";
import { PigeonTabEnum } from "../models/enums/PigeonTabEnum";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import usePigeonAssignments from "../hooks/usePigeonAssignments";

export interface PigeonSeasonGroupTableProps {
  choosenSeason?: null | number;
  type: PigeonTabEnum;
  interactiveRow?: boolean;
  searchValue?: string | undefined;
}

const PigeonSeasonGroupTable = ({
  choosenSeason,
  type,
  interactiveRow,
  searchValue,
}: PigeonSeasonGroupTableProps) => {
  const navigate = useNavigate();
  const { assignments, lastMeta, fetchNextPage, busy } = usePigeonAssignments(
    new PageOptions({
      filters: `pigeon.ring.number=='=LIKE=${searchValue}=LIKE=' && season.year==${choosenSeason} && group=='${type}'`,
    })
  );

  return (
    <Table
      overflowMode="Popin"
      loading={busy}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
          {!breakpoints.isPhone() && (
            <TableHeaderCell>{getText("General.PigeonName")}</TableHeaderCell>
          )}
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeons/${choosenSeason}/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {assignments?.map((assignment) => (
        <TableRow
          interactive={interactiveRow}
          rowKey={assignment.pigeonId.toString()}
          id={assignment.id.toString()}
          key={assignment.id}
        >
          <TableCell>{assignment.pigeon.RingNumber}</TableCell>
          {!breakpoints.isPhone() && (
            <TableCell>{assignment.pigeon.ReadableName}</TableCell>
          )}
        </TableRow>
      ))}
    </Table>
  );
};

export default PigeonSeasonGroupTable;
