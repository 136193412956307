import { useTheme } from "styled-components";
import { HBox, VBox } from "../../styles/Flexbox.styled";
import { Button } from "@ui5/webcomponents-react";
import Markdown from "marked-react";
import { AdType } from "../../models/enums/AdType";
import useAd from "../../hooks/useAd";

interface VerticalAdProps {
  name: string;
}

const VerticalAd = ({ name }: VerticalAdProps) => {
  const theme = useTheme();
  const { ads, close, ref } = useAd(AdType.Vertical, name);

  if (!ads) return null;

  return (
    <VBox
      className="PigeonMap__ads"
      ref={ref}
      Padding="5px"
      width="100%"
      height="100%"
      backgroundColor={theme.palette.White}
      shadow="small"
    >
      <HBox Padding="0 5px 0 0" justifyContent="flex-end">
        <Button onClick={close} icon="decline" />
      </HBox>
      <Markdown>{ads?.mdText}</Markdown>
    </VBox>
  );
};

export default VerticalAd;
