import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";
import SelectOption from "../../../models/SelectOption";
import { getText } from "../../../locales/initI18n";

const getPigeonSexOptions = () => [
  new SelectOption({
    key: PigeonSexEnum.Male,
    text: getText("General.Male")
  }),
  new SelectOption({
    key: PigeonSexEnum.Female,
    text: getText("General.Female"),
  }),
  new SelectOption({
    key: PigeonSexEnum.Unknown,
    text: getText("General.SexUnknown"),
  }),
];

export const getInitialSex = (current?: PigeonSexEnum) => {
  const option = getPigeonSexOptions().find((option) => option.key === current);
  if (option) return option;
  return getPigeonSexOptions()[2];
};

export default getPigeonSexOptions;
