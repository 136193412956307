import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import PageOptions from "../models/PageOptions";
import { getAssignments } from "../services/PigeonSeasonAssignmentService";

const usePigeonAssignments = (options = new PageOptions()) => {
  const queryClient = useQueryClient();

  const queryFlights = useInfiniteQuery({
    queryKey: options.getQueryKey("pigeon-season-assignments"),
    queryFn: ({ pageParam = 1 }) => getAssignments(options.setPage(pageParam)),
    getNextPageParam: options.nextPagefn,
  });

  const invalidate = () => {
    queryClient.invalidateQueries(["pigeon-season-assignments"]);
    queryClient.invalidateQueries(["pigeon-season-assignment"]);
  };

  return {
    assignments: queryFlights.data?.pages.flatMap((page) => page.data),
    lastMeta: queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta,
    busy: queryFlights.isLoading,
    refetch: queryFlights.refetch,
    invalidate,
    fetchNextPage: queryFlights.fetchNextPage,
  };
};

export default usePigeonAssignments;
