import { Button } from "@ui5/webcomponents-react";
import { HBox } from "../../styles/Flexbox.styled";
import HalfModal from "../HalfModal/HalfModal";
import { ModalProps } from "./IModalOptions";
import RingNumberInput from "../RingNumberInput";
import { getText } from "../../locales/initI18n";
import { useState } from "react";

interface AddRingNumberProps extends ModalProps<string> {}

const AddRingNumber = ({ onClose, onConfirm }: AddRingNumberProps) => {
  const [ringNumber, setRingNumber] = useState("");

  return (
    <HalfModal
      title={getText("AddRingNumber.ChooseRingNumber")}
      description={getText("AddRingNumber.ChooseRingumberExplanation")}
      isExpander={false}
      heightDivider={2.0}
      visible
      onClose={onClose}
    >
      <RingNumberInput
        value={ringNumber}
        onChange={(value) => {
          setRingNumber(value);
        }}
      />
      <HBox justifyContent="flex-end">
        <Button design="Emphasized" onClick={() => onConfirm(ringNumber)}>
          {getText("AddRingNumber.Ringing")}
        </Button>
      </HBox>
    </HalfModal>
  );
};

export default AddRingNumber;
