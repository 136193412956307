import { useEffect, useRef, useState } from "react";
import { AdType } from "../models/enums/AdType";
import AdHelper from "../utils/AdHelper";
import Ad from "../models/Ad";
import { getAd } from "../services/AdsService";

const useAd = (type: AdType, name = "") => {
  const [isAdVisible, setIsAdVisible] = useState(
    !AdHelper.isAddShownToday(type, name)
  );
  const [ads, setAds] = useState<Ad>();
  const markdownRef = useRef<HTMLDivElement>(null);

  const closeAd = () => {
    const cookie = AdHelper.getCookie(type, name);
    if (!cookie) throw new Error("Wrong Ad type to close");
    cookie.set(new Date().toISOString());
    setIsAdVisible(false);
  };

  const fetchAd = async () => {
    if (!isAdVisible) return;
    const ad = await getAd(type);
    if (!ad) return;
    setAds(ad);
  };

  useEffect(() => {
    void fetchAd();
  }, []);

  useEffect(() => {
    const markdownElement = markdownRef.current;
    if (markdownElement) {
      markdownElement.addEventListener("click", AdHelper.linkClickHandler());
    }

    return () => {
      if (markdownElement) {
        markdownElement.removeEventListener(
          "click",
          AdHelper.linkClickHandler()
        );
      }
    };
  }, [markdownRef, markdownRef.current]);

  return { ads: !isAdVisible ? null : ads, ref: markdownRef, close: closeAd };
};

export default useAd;
