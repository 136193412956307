export enum PigeonColorEnum {
  Red,
  Blue,
  Dark,
  Grey,
  White,
  Black,
  Fawn, // Płowy
  FawnRed, // Płowy czerwony
  FawnBlack, // Płowy czarny
  SpottedGrey, // Szpakowaty nakrapiany
  SpottedBlue,
  SpottedRed,
  SpottedDark,
  VaricolouredBlue, // Niebieski pstrokaty
  VaricolouredDark, // Ciemny pstrokaty
  VaricolouredBlack, // Czarny pstrokaty
  VaricolouredRed, // Czerwony pstrokaty
  VaricolouredFawn, // Płowy pstrokaty
  VaricolouredGrey, // Szpakowaty pstrokaty
  VaricolouredFawnRed, // Płowy czerwony pstrokaty
  VaricolouredFawnBlack, // Płowy czarny pstrokaty
  SpottedVaricolouredBlue, // Niebieski pstrokaty nakrapiany
  SpottedVaricolouredDark, // Ciemny pstrokaty nakrapiany
  SpottedVaricolouredBlack, // Czarny pstrokaty nakrapiany
  SpottedVaricolouredRed, // Czerwony pstrokaty nakrapiany
  SpottedVaricolouredFawn, // Płowy pstrokaty nakrapiany
  None,
}
