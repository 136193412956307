import {
  FlexibleColumnLayout,
  MessageBoxAction,
  MessageBoxType,
} from "@ui5/webcomponents-react";
import { useEffect, useState } from "react";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import AddEgg from "../components/modals/AddEgg";
import BaseDialogProps from "../models/BaseDialogProps";
import ConfirmMessageHelper from "../utils/ConfirmMessageHelper";
import DialogResult from "../models/DialogResult";
import Egg from "../models/Egg";
import FCLLayout from "@ui5/webcomponents-fiori/dist/types/FCLLayout";
import HatchEgg from "../components/modals/HatchEgg";
import Pigeon from "../models/Pigeon";
import { PigeonAssignMode } from "../models/enums/PigeonAssignMode";
import PigeonAssignTable from "../components/PigeonAssignPage";
import PigeonPair from "../models/PigeonPair";
import PigeonPairPage from "../components/PigeonPairPage";
import PigeonSeasonPage from "../components/PigeonSeasonPage";
import PigeonSelectDialog from "../components/modals/PigeonSelectDialog";
import { assignToSeason } from "../services/PigeonSeasonAssignmentService";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import { useParams } from "react-router-dom";
import usePigeonAssignments from "../hooks/usePigeonAssignments";
import withNavbar from "../hoc/withNavbar/withNavbar";
import useMessage from "../hooks/useMessage";
import useToast from "../hooks/useToast";

const Pigeons = (props: WithDialogsProps) => {
  const { season } = useParams();
  const { openMessage } = useMessage();
  const { showToast } = useToast();
  const [pressedPair, setPressedPair] = useState<PigeonPair>();
  const [choosenSeason, setChoosenSeason] = useState<number | null>(null);
  const [assignMode, setAssignMode] = useState(PigeonAssignMode.None);
  const { invalidate } = usePigeonAssignments();
  const [contentLayout, setContentLayout] = useState<FCLLayout>(
    FCLLayout.OneColumn
  );
  const dispatch = useAppDispatch();

  const setPigeonsToSeason = async (pigeons: Pigeon[]) => {
    if (!choosenSeason) return;
    if (pigeons.length === 0) {
      openMessage(getText("Pigeons.NoChoosePigeonForSeason"), {
        type: MessageBoxType.Warning,
      });
      return;
    }
    const group = await ConfirmMessageHelper.getSeasonAssignment(openMessage);
    if (!group) return;
    for (const pigeon of pigeons) {
      try {
        await assignToSeason(pigeon, choosenSeason, group);
        showToast(
          getText("Pigeons.PigeonsAssigned", { season: choosenSeason, group })
        );
      } catch (e) {
        dispatch(setError(e));
        break;
      }
    }
    invalidate();
  };

  useEffect(() => {
    if (Number(season) !== choosenSeason) {
      setChoosenSeason(season ? Number(season) : null);
    }
  }, [season, typeof season]);
  return (
    <>
      <FlexibleColumnLayout
        layout={contentLayout}
        style={{ height: "100%" }}
        startColumn={
          <PigeonSeasonPage
            onPairPress={(value) => {
              setPressedPair(value);
              setAssignMode(PigeonAssignMode.Pair);
              setContentLayout(FCLLayout.TwoColumnsStartExpanded);
            }}
            assignMode={assignMode}
            contentLayout={contentLayout}
            choosenSeason={choosenSeason}
            onChangeAssignMode={setAssignMode}
            onChangeLayout={setContentLayout}
            onChangeSeason={setChoosenSeason}
          />
        }
        midColumn={
          <>
            {assignMode === PigeonAssignMode.Group && (
              <PigeonAssignTable
                assignMode={assignMode}
                onAssign={setPigeonsToSeason}
                onClose={() => {
                  setAssignMode(PigeonAssignMode.None);
                  setContentLayout(FCLLayout.OneColumn);
                }}
                choosenSeason={choosenSeason}
              />
            )}
            {assignMode === PigeonAssignMode.Pair && (
              <PigeonPairPage
                year={Number(season)}
                pair={pressedPair}
                openSelectPigeon={(initFilters: string) =>
                  props.openDialog("PigeonSelectDialog", { initFilters })
                }
                onClose={() => {
                  setAssignMode(PigeonAssignMode.None);
                  setContentLayout(FCLLayout.OneColumn);
                  setPressedPair(undefined);
                }}
              />
            )}
          </>
        }
      />
    </>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "PigeonSelectDialog":
      return (
        <PigeonSelectDialog
          {...props}
          isVisible
          onClose={(value) =>
            onClose(
              new DialogResult(
                value ? MessageBoxAction.OK : MessageBoxAction.Close,
                value
              )
            )
          }
        />
      );
    case "AddEgg":
      return (
        <AddEgg
          {...props}
          isVisible
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
          onClose={(value) => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    case "HatchEgg":
      const egg = (props as { egg: Egg }).egg;
      return (
        <HatchEgg
          {...props}
          egg={egg}
          isVisible
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
          onClose={(value) => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    default:
      return null;
  }
};

export default withNavbar(withDialogs(Pigeons, renderDialogs));
