import { PigeonStateEnum } from "../../../models/enums/PigeonStateEnum";
import SelectOption from "../../../models/SelectOption";
import { getText } from "../../../locales/initI18n";

const getPigoenStates = () =>
  [
    // new SelectOption({
    //   key: PigeonStateEnum.Borrowed,
    //   text: "Borrowed",
    // }),
    new SelectOption({
      key: PigeonStateEnum.Dead,
      text: getText("Pigeon.state.Dead"),
    }),
    new SelectOption({
      key: PigeonStateEnum.Lost,
      text: getText("Pigeon.state.Lost"),
    }),
    new SelectOption({
      key: PigeonStateEnum.Sold,
      text: getText("Pigeon.state.Sold"),
    }),
    new SelectOption({
      key: PigeonStateEnum.Unknown,
      text: getText("Pigeon.state.Other"),
    }),
  ].sort((a, b) => a.text.localeCompare(b.text));

export const getInitialState = (current?: PigeonStateEnum) => {
  const option = getPigoenStates().find((state) => state.key === current);
  if (option) return option;
  return (
    getPigoenStates().find((state) => state.key === PigeonStateEnum.Unknown) ||
    getPigoenStates()[0]
  );
};

export default getPigoenStates;
