import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DynamicPage,
  DynamicPageTitle,
  FlexBox,
  MessageBoxAction,
  ObjectStatus,
  Tab,
  TabContainer,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableSelectionDomRef,
  Title,
  Toolbar,
  ToolbarButton,
  ToolbarSpacer,
} from "@ui5/webcomponents-react";
import { memo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withDialogs, { WithDialogsProps } from "../hoc/withDialogs/withDialogs";

import BackToolbarButton from "../components/BackToolbarButton";
import BaseDialogProps from "../models/BaseDialogProps";
import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign";
import Debounce from "../utils/Debounce";
import DialogResult from "../models/DialogResult";
import PageOptions from "../models/PageOptions";
import PigeonNotSelectedToSeasonTable from "../components/PigeonNotSelectedToSeasonTable";
import { PigeonSeasonSelectionGroup } from "../models/enums/PigeonSeasonSelectionGroup";
import RingDialog from "../components/RingDialog";
import TextInput from "../components/TextInput";
import { VBox } from "../styles/Flexbox.styled";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import usePigeonSeasonSelections from "../hooks/usePigeonSeasonSelections";
import withNavbar from "../hoc/withNavbar/withNavbar";
import withPigeonSeasonSelectionHandler from "../hoc/withPigeonSeasonSelection/withPigeonSeasonSelectionHandler";
import { withPigeonSeasonSelectionHandlerProps } from "../hoc/withPigeonSeasonSelection/withPigeonSeasonSelectionHandlerProps";
import useMessage from "../hooks/useMessage";

export interface PigeonSeasonSelectionPageProps {}

const PigeonSeasonSelectionPage = ({
  openDialog,
  addToSeasonSelection,
  moveSelection,
  removeSelection,
}: PigeonSeasonSelectionPageProps &
  WithDialogsProps &
  withPigeonSeasonSelectionHandlerProps) => {
  const navigate = useNavigate();
  const { season } = useParams();
  const { openMessage } = useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [tab, setTab] = useState(PigeonSeasonSelectionGroup.Old);
  const [isSelection, setIsSelection] = useState(false);
  const selection = useRef<TableSelectionDomRef>(null);

  const { selections, fetchNextPage, lastMeta, busy } =
    usePigeonSeasonSelections(
      new PageOptions({
        filters: `pigeon.ring.number=='=LIKE=${searchValue}=LIKE='&&season.year==${season}&&group=='${tab}'`,
        take: 50,
      })
    );

  const onAddToSeasonSelection = async () => {
    if (!selection.current) return;
    await addToSeasonSelection(selection.current, Number(season), false);
    setIsSelection(false);
  };

  return (
    <DynamicPage
      style={{
        width: "100%",
      }}
      titleArea={
        <DynamicPageTitle
          breadcrumbs={
            <Breadcrumbs
              onItemClick={(event) => {
                const item = event.detail.item;
                const pathname = item.dataset.href;
                if (!pathname) return;
                navigate({ pathname });
                setIsSelection(false);
              }}
            >
              <BreadcrumbsItem data-href="/dashboard/map">
                {getText("FlightList.breadbrumb.Map")}
              </BreadcrumbsItem>
              <BreadcrumbsItem data-href="/dashboard/pigeons">
                {getText("General.MyPigeons")}
              </BreadcrumbsItem>
              <BreadcrumbsItem data-href={`/dashboard/pigeons/${season}`}>
                {getText("General.Season")} ({season})
              </BreadcrumbsItem>
              <BreadcrumbsItem>
                {getText("PigeonSeasonSelectionPage.List")}
              </BreadcrumbsItem>
            </Breadcrumbs>
          }
          navigationBar={
            <Toolbar design="Transparent">
              <BackToolbarButton />
            </Toolbar>
          }
          actionsBar={<Toolbar design="Transparent"></Toolbar>}
          heading={
            <Title>{`${getText(
              "PigeonSeasonSelectionPage.List"
            )} (${season})`}</Title>
          }
        ></DynamicPageTitle>
      }
    >
      {isSelection && (
        <>
          <Toolbar alignContent="Start">
            <ToolbarSpacer overflowPriority="NeverOverflow" />
            <ToolbarButton
              overflowPriority="NeverOverflow"
              onClick={onAddToSeasonSelection}
              design="Emphasized"
              icon="add"
              text={getText(
                "PigeonSeasonSelectionPage.AddSelectedPigeonsToList"
              )}
            />
            <ToolbarButton
              overflowPriority="NeverOverflow"
              onClick={async () => {
                const confirm = await openMessage(
                  getText("PigeonSeasonSelectionPage.CancelAddingPigeonsToList")
                );
                if (confirm !== MessageBoxAction.OK) return;

                setIsSelection(false);
              }}
              icon="decline"
            />
          </Toolbar>
          <PigeonNotSelectedToSeasonTable
            year={Number(season)}
            selection={selection}
          />
        </>
      )}
      {!isSelection && (
        <>
          <FlexBox>
            <VBox width={breakpoints.isPhone() ? "100%" : "300px"}>
              <TextInput
                placeholder={getText(
                  "PigeonSeasonSelectionPage.SearchByRingNumber"
                )}
                onChange={new Debounce(setSearchValue, 500).exec}
              >
                {getText("General.RingNumber")}
              </TextInput>
            </VBox>
          </FlexBox>
          <Toolbar alignContent="End">
            <ToolbarButton
              overflowPriority="NeverOverflow"
              onClick={() => {
                setIsSelection(true);
              }}
              icon="add-activity-2"
              text={getText("PigeonSeasonSelectionPage.SelectPigeonsForList")}
            />
          </Toolbar>
          <TabContainer
            onTabSelect={(event) => {
              const tab = event.detail.tab.dataset.tab;
              if (!tab) return;
              setTab(tab as unknown as PigeonSeasonSelectionGroup);
            }}
            collapsed={true}
          >
            <Tab
              data-tab={PigeonSeasonSelectionGroup.Old}
              text={getText("PigeonSeasonSelectionPage.AdultList")}
            />
            <Tab
              data-tab={PigeonSeasonSelectionGroup.Young}
              text={getText("PigeonSeasonSelectionPage.YoungList")}
            />
          </TabContainer>
          <Table
            overflowMode="Scroll"
            loading={busy}
            headerRow={[
              <TableHeaderRow>
                <TableHeaderCell width="80px">
                  {getText("PigeonSeasonSelectionPage.Positio")}
                </TableHeaderCell>
                <TableHeaderCell minWidth="120px">
                  {getText("General.RingNumber")}
                </TableHeaderCell>
                <TableHeaderCell width="140px"></TableHeaderCell>
                <TableHeaderCell width="80px"></TableHeaderCell>
              </TableHeaderRow>,
            ]}
            onRowClick={(event) => {
              const key = event.detail.row.rowKey;
              navigate(`/dashboard/pigeon/${key}`);
            }}
          >
            {lastMeta?.isNext && (
              <TableGrowing
                type="Button"
                growing-text={getText("General.More")}
                slot="features"
                onLoadMore={() => fetchNextPage()}
              ></TableGrowing>
            )}
            {selections?.map((pigeon) => (
              <TableRow
                interactive
                rowKey={pigeon.pigeon.id.toString()}
                id={pigeon.id.toString()}
                key={`pigeonSeasonSelections-page-${pigeon.pigeon.id}`}
              >
                <TableCell>
                  <ObjectStatus
                    state={pigeon.position <= 50 ? "Positive" : "None"}
                    inverted
                  >
                    {pigeon.position}
                  </ObjectStatus>
                </TableCell>
                <TableCell>{pigeon.pigeon.RingNumber}</TableCell>
                <TableCell>
                  {pigeon.position > 50 && (
                    <Button onClick={() => moveSelection(pigeon.id, tab)}>
                      {getText("PigeonSeasonSelectionPage.MoveToTop50")}
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    design={ButtonDesign.Negative}
                    icon="delete"
                    onClick={() => removeSelection(pigeon)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </>
      )}
    </DynamicPage>
  );
};

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "RingDialog": {
      return (
        <RingDialog
          isVisible={true}
          onConfirm={() => {}}
          onClose={() => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    }
  }
};

export default memo(
  withNavbar(
    withDialogs(
      withPigeonSeasonSelectionHandler(PigeonSeasonSelectionPage),
      renderDialogs
    )
  ),
  (prev, next) => {
    return true;
  }
);
