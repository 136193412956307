import { useTheme } from "styled-components";
import { HBox } from "../../styles/Flexbox.styled";
import { Button } from "@ui5/webcomponents-react";
import Markdown from "marked-react";
import { AdType } from "../../models/enums/AdType";
import useAd from "../../hooks/useAd";

interface HorizontalAdProps {
  name: string;
}

const HorizontalAd = ({ name }: HorizontalAdProps) => {
  const theme = useTheme();
  const { ads, close, ref } = useAd(AdType.Horizontal, name);

  if (!ads) return null;

  return (
    <HBox width="100%" justifyContent="center">
      <HBox
        ref={ref}
        className="PigeonMap__ads"
        shadow="small"
        BorderRadius="10px"
        backgroundColor={theme.palette.White}
        Padding="10px"
        maxWidth="600px"
        height="150px"
        alignItems="center"
      >
        <Markdown baseURL="">{ads?.mdText}</Markdown>
        <HBox height="100%">
          <Button icon="decline" onClick={close} />
        </HBox>
      </HBox>
    </HBox>
  );
};

export default HorizontalAd;
