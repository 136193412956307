import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import PageOptions from "../models/PageOptions";
import { getSeasonSelections } from "../services/PigeonSeasonSelectionService";

const usePigeonSeasonSelections = (options = new PageOptions()) => {
  const queryClient = useQueryClient();

  const queryFlights = useInfiniteQuery({
    queryKey: options?.getQueryKey("pigeon-season-selection"),
    queryFn: ({ pageParam = 1 }) =>
      getSeasonSelections(options.setPage(pageParam)),
    getNextPageParam: options?.nextPagefn,
  });

  const removeCaches = () => {
    queryClient.invalidateQueries(["pigeon-season-selection"]);
    queryClient.invalidateQueries(["pigeons", "pigeon-season-selection"]);
  };

  return {
    selections: queryFlights.data?.pages.flatMap((page) => page.data) || [],
    lastMeta: queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta,
    busy: queryFlights.isLoading,
    refetch: queryFlights.refetch,
    fetchNextPage: queryFlights.fetchNextPage,
    invalidate: removeCaches,
  };
};

export default usePigeonSeasonSelections;
