import { FlightMarker, MapBuilderMarker, Marker } from "../models/Marker";
import { MapMouseEvent, Popup } from "@maptiler/sdk";

import DateHelper from "./DateHelper";
import { Flight } from "../models/Flight";
import FlightHelper from "./FlightHelper";
import { FlightMarkerPosition } from "../models/enums/FlightMarkerPosition";
import Formatter from "./Formatter";
import { ITag } from "../models/Tag";
import MapBuilder from "./MapBuilder";
import MapFlight from "../models/MapFlight";
import { Weather } from "../models/Weather";

export default class MapFlightBuilder extends MapBuilder {
  private _mapFlights: MapFlight[] = [];
  private _FLIGHTED_PREFIX = "FLIGHTED_";
  private _FLIGHT_PREFIX = "FLIGHT_";
  private TAG_PREFIX = "TAG_";
  private _TAGS_GROUP = "TAGS";
  private _showWeatherPopups = true;
  private _getWeather: (marker: Marker) => Promise<Weather>;

  constructor(
    id: string,
    onClickMap: (marker: Marker | null) => void,
    getWeather: (marker: Marker) => Promise<Weather>
  ) {
    super(id, onClickMap);
    this._getWeather = getWeather;
  }

  //#region Public Methods
  public onClickMap(
    e: MapMouseEvent,
    callback: (marker: Marker | null) => void
  ): void {
    super.onClickMap(e, callback);
    if (!this._showWeatherPopups) return;

    const marker = this.getCurrentMarker();
    if (marker) this.addWeatherPopup(marker);
  }
  public showFlight(flight: Flight, onClick: (marker: FlightMarker) => void) {
    const flightName = flight.id.toString();

    if (flight.coordinates.length === 0) {
      throw new Error("MAP_FLIGHT_ERROR");
    }

    if (this.getMapFlight(flightName)) return;

    if (!FlightHelper.isWeatherLayer(flight)) {
      this.hideCurrentLayer();
    }

    flight.coordinates.forEach((marker) => {
      const mapBuilderMarker = this.addMarker(marker, {
        image: this.getIcon(marker),
        onClick: onClick,
        group: marker.flightId,
        name: `${this._FLIGHT_PREFIX}${marker.estimateDate}`,
      });

      if (this._showWeatherPopups && mapBuilderMarker) {
        this.addWeatherPopup(mapBuilderMarker);
      }
    });
    const mapFlight = this.addMapFlight(flight);
    this.addLine(flightName, flight.coordinates);
    this.addFlightedLine(flight);
    mapFlight.interval = setInterval(() => {
      this.addFlightedLine(flight);
    }, 10000);
    this.zoomToMarkers(flight.coordinates, 100);
  }
  public removeFlight(name: string) {
    const removedMapFlight = this.removeMapFlight(name);
    if (!removedMapFlight) return;
    clearInterval(removedMapFlight?.interval);
    this.removeMarkersByGroup(name);
    this.removeLine(name);
    this.removeLine(`${this._FLIGHTED_PREFIX}${name}`);
  }
  public addTags(tags: ITag[], onClick: (marker: FlightMarker) => void) {
    tags.forEach((tag) => {
      if (tag.isVisible) {
        const mapBuilderMarker = this.addMarker(tag, {
          image: this.getIcon(tag),
          onClick,
          group: this._TAGS_GROUP,
          name: `${this.TAG_PREFIX}${tag.id}`,
        });
        if (this._showWeatherPopups && mapBuilderMarker) {
          this.addWeatherPopup(mapBuilderMarker);
        }
      }
    });
  }
  public removeTags() {
    this.removeMarkersByGroup(this._TAGS_GROUP);
  }
  public hidePopups() {
    this._showWeatherPopups = false;
    this.getMarkers().forEach((marker) => {
      if (marker.mapMarker.getPopup().isOpen()) marker.mapMarker.togglePopup();
    });
  }
  public showPopups() {
    this._showWeatherPopups = true;
    this.getMarkers().forEach((marker) => {
      this.addWeatherPopup(marker);
    });
  }
  public refreshPopups() {
    if (!this._showWeatherPopups) return;
    this.showPopups();
  }
  public getShowWeatherPopups() {
    return this._showWeatherPopups;
  }
  //#endregion Public Methods
  //#region Protected Methods
  protected getIcon(marker: Marker) {
    const flightMarker = marker as FlightMarker;
    if (flightMarker.position === FlightMarkerPosition.START)
      return `url(/assets/start-flight-marker.png)`;
    if (flightMarker.position === FlightMarkerPosition.END)
      return `url(/assets/end-flight-marker.png)`;
    if (flightMarker.position === FlightMarkerPosition.MIDDLE)
      return `url(/assets/middle-flight-marker.png)`;
    const tag = marker as ITag;
    if (tag.isCote) return `url(/assets/end-flight-marker.png)`;
    return `url(/assets/start-flight-marker.png)`;
  }
  protected getMapFlight(name: string) {
    return this._mapFlights.find((mapFlight) => mapFlight.name === name);
  }
  protected addMapFlight(flight: Flight) {
    const mapFlight = new MapFlight();
    mapFlight.flight = flight;
    this._mapFlights.push(mapFlight);
    return mapFlight;
  }
  protected removeMapFlight(name: string) {
    const removedMapFlight = this.getMapFlight(name);
    this._mapFlights = this._mapFlights.filter(
      (mapFlight) => mapFlight.name !== name
    );
    return removedMapFlight;
  }
  protected addFlightedLine(flight: Flight) {
    const now = new Date();
    const flightName = flight.id.toString();
    const flightedName = `${this._FLIGHTED_PREFIX}${flightName}`;

    flight.coordinates.forEach((marker) => {
      if (
        new Date(marker.estimateDate) <= now &&
        marker.position === FlightMarkerPosition.MIDDLE
      ) {
        this.changeMarkerIcon(
          `${this._FLIGHT_PREFIX}${marker.estimateDate}`,
          "/assets/middle-active-flight-marker.png"
        );
      }
    });

    const startMarker = flight.coordinates.find(
      (marker) => marker.position === FlightMarkerPosition.START
    ) as FlightMarker;
    const endMarker = flight.coordinates.find(
      (marker) => marker.position === FlightMarkerPosition.END
    ) as FlightMarker;
    const dateDiff =
      new Date(endMarker.estimateDate || 0).getTime() -
      new Date(startMarker.estimateDate || 0).getTime();
    const startDateDiff =
      now.getTime() - new Date(startMarker.estimateDate || 0).getTime();
    const endDateDiff =
      new Date(endMarker.estimateDate || 0).getTime() - now.getTime();
    const ratio = startDateDiff / dateDiff;

    if (startDateDiff < 0) return this.addLine(flightedName, [], "#EED202");
    if (endDateDiff < 0) {
      const mapFlight = this.getMapFlight(flightName);
      if (mapFlight) mapFlight.clearInterval();
      return this.addLine(flightedName, flight.coordinates, "#EED202");
    }
    const startLat = Number(startMarker.latitude);
    const startLng = Number(startMarker.longitude);
    const endLat = Number(endMarker.latitude);
    const endLng = Number(endMarker.longitude);

    const flightedMarker = {
      latitude: startLat + (endLat - startLat) * ratio,
      longitude: startLng + (endLng - startLng) * ratio,
    } as FlightMarker;

    const middleMarkers = [];
    for (let index = 1; index < flight.coordinates.length - 2; index++) {
      const coordinate = flight.coordinates[index];
      const dateDiff = DateHelper.getDiff(coordinate.estimateDate, now);

      if (dateDiff < 0) break;

      const marker = {
        latitude: Number(flight.coordinates[index].latitude),
        longitude: Number(flight.coordinates[index].longitude),
      } as Marker;
      middleMarkers.push(marker);
    }

    this.addLine(
      flightedName,
      [startMarker, ...middleMarkers, flightedMarker],
      "#EED202"
    );
  }
  protected getPopup(marker: MapBuilderMarker) {
    const popup = marker.mapMarker.getPopup();
    if (!popup)
      return new Popup({
        offset: [0, -10],
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        className: "pigeonmap-map-popup-container",
      });
    return popup;
  }
  protected async addWeatherPopup(marker: MapBuilderMarker) {
    const weather = await this._getWeather(marker);
    const popup = this.getPopup(marker).setHTML(
      `<p class="pigeonmap-map-popup-text">${Formatter.WindSpeed(
        weather.windSurface
      )} | ${Formatter.Liquid(weather.liquid)}</p>`
    );
    if (!marker.mapMarker.getPopup()) marker.mapMarker.setPopup(popup);
    if (!marker.mapMarker.getPopup().isOpen()) marker.mapMarker.togglePopup();
  }
  //#endregion Protected Methods
  //#region Private Methods

  //#endregion Private Methods
}
